import { sleep } from '../utils/helpers';
import {createFormDataFromMap} from '../utils/helpers'

/*The base URL of the API endpoint.*/
const API_URL = process.env.REACT_APP_API_URL

/*Sends a POST request to the specified URL with the provided data.*/
const addNewData = (url,data)=>{
    const options = {
        method: 'POST',
        body: data /*Creates a FormData object from a map of key-value pairs.*/
    };
    return new Promise(async (res, rej) => {

        /*A promise that resolves with the fetched data if the response status is true,
         * or rejects with an error message if there was an error or the response status is false.*/
        fetch(`${API_URL}/${url}`, options)
        .then((response) => {
            return response.json();
          })
        .then(response => {
          if (response.status === true){
              return res({ status: true, data: response});  
          }else{
            return rej({ status:false,message: response.msg,})
          }
        })
        .catch(err => {
          return rej({ message: err.message,status:false})
        });
      });
}

export default addNewData;