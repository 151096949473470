import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // menuItem: [],
    cartItem: [],
    menuItemWithId: {},
    cartIsOpen: false,
    emptyTab:[]
};

const deepCopy = (data, id) => {
    const deepCopy = JSON.parse(JSON.stringify(data))
    let idData = ''
    let idType = ''
    Object.keys(deepCopy).forEach((key) => {
        if (deepCopy[key][id]) {
            idData = deepCopy[key][id]
            idType = key
        }
        return false
    }, {})
    return { idData, idType }
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenuItem: (state, action) => {
            // state.menuItem = action.payload;
            const menu = action.payload

            Object.keys(menu).forEach((key) => {
                if (menu[key]?.length <= 0){
                    state.emptyTab.push(key)
                }
                state[`menuItemWithId`][key] = menu[key].reduce((accumulator, item) => {
                    // state.menuItemWithId[item.product_id] = item;
                    accumulator[item.product_id] = item;
                    return accumulator;
                }, {});
            })
        },
        addToCart: (state, action) => {
            const addedProductId = action.payload;
            const menuItemWithIdCopy = deepCopy(state.menuItemWithId, addedProductId);

            // Check if the product is already in the cart
            if (!state.cartItem.includes(addedProductId)) {
                state.cartItem.push(addedProductId);
            }

            const updatedProduct = {
                ...menuItemWithIdCopy['idData'],
                in_cart: true,
                item_qty: (menuItemWithIdCopy?.item_qty || 0) + 1,
            };
            state.menuItemWithId[menuItemWithIdCopy['idType']][addedProductId] = updatedProduct;
            state.cartIsOpen = true
        },

        increaseCartItemQuantity: (state, action) => {
            const increasedProductId = action.payload;
            const menuItemWithIdCopy = deepCopy(state.menuItemWithId, increasedProductId);

            const updatedProduct = {
                ...menuItemWithIdCopy['idData'],
                item_qty: (menuItemWithIdCopy['idData']?.item_qty || 0) + 1,
            };

            state.menuItemWithId[menuItemWithIdCopy['idType']][increasedProductId] = updatedProduct;

        },

        decreaseCartItemQuantity: (state, action) => {
            const decreasedProductId = action.payload;
            const menuItemWithIdCopy = deepCopy(state.menuItemWithId, decreasedProductId);

            const updatedProduct = {
                ...menuItemWithIdCopy['idData'],
                item_qty: (menuItemWithIdCopy['idData']?.item_qty || 0) - 1,
            };

            if (updatedProduct.item_qty <= 0) {
                updatedProduct.in_cart = false;
                state.cartItem = state.cartItem.filter((id) => id !== decreasedProductId);

                // Check if cartItem length is zero and set cartIsOpen accordingly
                if (!(state.cartItem.length > 0)) {
                    state.cartIsOpen = false;
                    document.body.classList.remove('disable-scroll');
                }
            }

            state.menuItemWithId[menuItemWithIdCopy['idType']][decreasedProductId] = updatedProduct;

        },
        clearCart: (state) => {
            var cloneProduct = JSON.parse(JSON.stringify(state.menuItemWithId));
            state.cartItem.forEach((productId) => {
                Object.keys(cloneProduct).forEach((key) => {

                    if (cloneProduct[key][productId]) {
                        cloneProduct[key][productId] = {
                            ...cloneProduct[key][productId], // Update 'cloneProduct' instead of 'state.cloneProduct'
                            in_cart: false,
                            product_qty: 0,
                        };
                    }
                })
            });
            state.menuItemWithId = cloneProduct;
            state.cartItem = [];
            state.cartIsOpen = false
        },
    }
});

export const { setMenuItem, addToCart, increaseCartItemQuantity, decreaseCartItemQuantity, clearCart } = menuSlice.actions;

export default menuSlice.reducer;
