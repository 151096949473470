import React from "react";
import { HiOutlineXCircle } from "react-icons/hi";
import CustomDialog from '../../modules/dialog/CustomDialog';
import { useSelector } from "react-redux";
import { t } from 'i18next';

function LogoutByAdmin() {
    const {infoLogOutAdmin} = useSelector(state => ({
        infoLogOutAdmin: state.logOut.infoLogOutAdmin
    }))

    const handleScanPage = () => {
        const localLanguage = localStorage.getItem("language");
        const localFcm = localStorage.getItem("baseNotification");
        // Clear the localStorage
        localStorage.clear();

        // Set the language preference back to the localStorage
        localStorage.setItem("language", localLanguage);
        localStorage.setItem("baseNotification", localFcm);
        window.location.href = "/login";
    };
    return (
        // ...
        <CustomDialog 
            isShow={infoLogOutAdmin} 
            handleClose={()=>{}}
            icon={<HiOutlineXCircle className="h-6 w-6 text-red-500" aria-hidden="true" />}
            title={t('dialog.logOut.byAdminTitle')}
            message={t('dialog.logOut.byAdminMessage')}
            btnText={t('dialog.logOut.byAdminBtn')}
            btnAction={handleScanPage}
        />
    );
}

export default LogoutByAdmin;
