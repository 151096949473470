import React from 'react'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { TiPhone, TiWorld } from 'react-icons/ti'
import { MdEmail } from 'react-icons/md'
import { HiPhone } from 'react-icons/hi'
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    const language = 'english'
    return (
        <section className=" w-full  main mt-2  " id='main-container' >
            <div className=" p-2 pl-4">
                <div className="" id="contactus">
                    <div className="flex  justify-center">
                        <div className="w-full">
                            <div className="wrapper">
                                <div className=" gap-0 justify-center ">
                                    <div className="max-w-3xl mx-auto flex justify-center p-3">
                                        <div className="z-[1] contact-wrap  md:p-8 p-4 relative mb-8 flex w-full min-w-0 flex-col overflow-visible break-words rounded-xl bg-white/40 bg-clip-border shadow-3 backdrop-blur-md pb-6">
                                            <h3 className="mb-4 text-2xl font-semibold ">{t('contact.contactHeading')}</h3>
                                            <form
                                                method="POST"
                                                id="contactForm"
                                                name="contactForm"
                                                className="contactForm"
                                            >
                                                <div className="row pb-12">
                                                    <div className='flex flex-col md:flex-row gap-2 md:mb-2'>
                                                        <div className="md:w-6/12 mb-2 md:mb-0">
                                                            <div className="form-group">
                                                                <div class="">
                                                                    <label for="" class="text-sm font-semibold px-1">{t('contact.nameInput')}</label>
                                                                    <div class="flex">
                                                                        <input type="text" name='fullName' class="w-full  pl-2 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" placeholder={t('contact.nameLabel')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="md:w-6/12 mb-2 md:mb-0">
                                                            <div className="form-group">
                                                                <div class="">
                                                                    <label for="" class="text-sm font-semibold px-1">{t('contact.emailInput')}</label>
                                                                    <div class="flex">
                                                                        <input type="email" name='email' class="w-full  pl-2 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" placeholder={t('contact.emailLabel')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="md:w-full mb-2" >
                                                        <div className="form-group">
                                                            <div class="">
                                                                <label for="" class="text-sm font-semibold px-1">{t('contact.subjectInput')}</label>
                                                                <div class="flex">
                                                                    <input type="text" name='subject' class="w-full  pl-2 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" placeholder={t('contact.subjectLabel')} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="w-full lg:w-12/12 mb-2">
                                                        <div class="relative w-full mb-3">
                                                            <label for="" class="text-sm font-semibold px-1">{t('contact.messageInput')}</label>

                                                            <textarea
                                                                style={{ resize: 'vertical', minHeight: '100px', maxHeight: '300px' }} name="message"
                                                                type="text" class="w-full  pl-2 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" placeholder={t('contact.messageLabel')} rows="4"></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="w-full">
                                                        <div className="form-group text-center">
                                                            <button
                                                                type="submit"
                                                                value="Send Message"
                                                                className="bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 border border-primary-700 rounded"
                                                            >
                                                                {t('contact.sendButton')}
                                                            </button>
                                                            <div className="submitting" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className=" relative mb-24 flex flex-wrap gap-y-6 justify-around rounded-lg mt-[-90px]  p-8 pt-24 bg-primary-200 shadow-md contacts_icon">
                                    <div className="md:w-3/12 p-2 h-full" >
                                        <div className="dbox w-full text-center h-full bg-primary-600 rounded-xl p-3">
                                            <div className="icon flex items-center justify-center">
                                                <HiPhone size={32} className='text-white' />
                                            </div>
                                            <div className="text ">
                                                <p className='text-primary-100'>
                                                    <span className='font-semibold text-primary-50'>{t('contact.phoneInfo')}:</span>
                                                    <br /> <a href="tel://1234567920">+91 9039240491</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:w-3/12 p-2 h-full">
                                        <div className="dbox w-full text-center h-full bg-primary-600 rounded-xl p-3">
                                            <div className="icon flex items-center justify-center">
                                                <MdEmail size={32} className='text-white' />
                                            </div>
                                            <div className="text">
                                                <p className='text-primary-100'>
                                                    <span className='font-semibold text-primary-50'>{t('contact.emailInfo')}:</span> <br />
                                                    <a href="mailto:info@yoursite.com">support@fudly.in</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:w-3/12 p-2 h-full">
                                        <div className="dbox w-full text-center h-full bg-primary-600 rounded-xl p-3">
                                            <div className="icon flex items-center justify-center">
                                                <TiWorld size={32} className='text-white' />
                                            </div>
                                            <div className="text">
                                                <p className='text-primary-100'>
                                                    <span className='font-semibold text-primary-50'>{t('contact.websiteInfo')}</span> <br /> <a href="https://fudly.in/web">https://fudly.in/web</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:w-9/12 p-2 h-full">
                                        <div className="dbox w-full text-center bg-primary-600 rounded-xl p-3">
                                            <div className="icon flex items-center justify-center">
                                                <HiOutlineLocationMarker size={32} className='text-white' />
                                            </div>
                                            <div className="text">
                                                <p className='break-words text-primary-100'>
                                                    <span className='font-semibold text-primary-50'>{t('contact.addressInfo')}:</span> <br />
                                                    <b>
                                                    {t('contact.addressInfoDetailTitle')}
                                                    </b>
                                                    <br/>
                                                    {t('contact.addressInfoDetailBody')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact