import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isPreviewShow: false,
    productPreviewId: null,
    productType: '',
    mode:'user'
};

export const productPreviewSlice = createSlice({
    name: 'productPreview',
    initialState,
    reducers: {
        showProductPreview: (state, action) => {
            const info = action.payload
            state.isPreviewShow= info.show;
            state.productPreviewId= info.id;
            state.productType= info.type;
            state.mode= info.mode;
        }
    }
});

export const { showProductPreview } = productPreviewSlice.actions;

export default productPreviewSlice.reducer;
