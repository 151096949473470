import React, { useState, useEffect } from 'react';
import { BiFoodMenu } from 'react-icons/bi'
import { IoFastFoodOutline } from 'react-icons/io5'
import { TiContacts } from 'react-icons/ti'
import { TbInfoCircle, TbLogout } from 'react-icons/tb'
import { TfiMore } from 'react-icons/tfi'
import { Transition } from '@headlessui/react';
import { Link } from "react-router-dom";
import LogoutDialog from '../../dialogs/log-out/LogoutDialog';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const BottomTabGuest = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false)
  const { t } = useTranslation();
  const { orderCountTotal } = useSelector(state => ({
    orderCountTotal: state.orderData.orderCountTotal
  }))

  // Get the current location object
  const location = useLocation();

  // Access the pathname (current path) from the location object
  const currentPath = location.pathname;

  const toggleDropdown = () => {
    setIsOpen(prev => {
      if (!prev) {
        document.body.classList.add('disable-scroll');
      } else {
        document.body.classList.remove('disable-scroll');
      }
      return !prev
    });

  };


  return (
    <>
      <Transition
        key={'menuDropdownOverlay'}
        as={React.Fragment}
        show={isOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div onClick={toggleDropdown} className='w-screen h-screen fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity top-0 left-0 z-20 max-w-xl mx-auto'></div>
      </Transition>

      <div className={`w-full z-20 bottomTabBar transition-all pt-safe text-[16px] text-md-light-on-surface dark:text-md-dark-on-surface fixed max-w-xl`}>
        <div className={`absolute w-full h-full left-0 top-0 bg-gray-100 shadow-3`} >
        </div>
        <div className="flex relative items-center w-full  justify-start h-16 pl-safe pr-safe">
          {/* nav - start */}
          <nav className="sticky bottom-0 mx-auto flex w-full flex-col justify-center items-center bg-gray-100 px-2 px-sm-10 pt-2 pb-1 text-xs text-center sm:max-w-md sm:rounded-t-xl  sm:text-sm">
            <p>
              The selected table is booked. You can only view the menu.
            </p>
            <p className='text-slate-500'>
              <span>For orders, please scan an available table.</span>
              <span className='text-blue-600 underline'><Link to={'/login'} className='inline'> Go To Scan Page</Link></span>
            </p>
          </nav>
          {/* nav - end */}
        </div>
      </div>
    </>

  )
}

export default BottomTabGuest