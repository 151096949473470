import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isSearchBarOpen: false,
    searchType: null,
};

export const searchBarSlice = createSlice({
    name: 'searchBar',
    initialState,
    reducers: {
        setSearchBar: (state, action) => {
            const search = action.payload
            state.isSearchBarOpen= search.show;
            state.searchType= search.type;
        }
    }
});

export const { setSearchBar } = searchBarSlice.actions;

export default searchBarSlice.reducer;
