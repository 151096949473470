import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  authentication: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthentication: (state, action) => {
      state.authentication = action.payload;
    },
  }
});

export const { setAuthentication, setProfileData } = authSlice.actions;

export default authSlice.reducer;
