import { useState, useEffect } from 'react'
import CustomDialog from '../../modules/dialog/CustomDialog'
import { t } from 'i18next';
import { getToken, getMessaging } from "firebase/messaging";
import { HiOutlineCheck } from 'react-icons/hi';

const API_URL = process.env.REACT_APP_API_URL;

const NotificationPermission = ({ handleClose, isOpen, notificationDialog }) => {
    const [open, setOpen] = useState(false)
    const messaging = getMessaging();
    const [isGrantingPermission, setIsGrantingPermission] = useState(false);

    const customClose = ()=>{
        handleClose()
        setOpen(false)
    }

    useEffect(() => {
        const fcm = localStorage.getItem('baseNotification')
        if (fcm) {
            try {
                // decode fcm token
                let decodedFcm = JSON.parse(atob(fcm))
                if (decodedFcm) {
                    const formData = new FormData();
                    formData.append('device_token', decodedFcm)
                    formData.append('table_unique_num', localStorage.getItem('table_id'))
                    fetch(`${API_URL}/api/allfunctionuser/device_token_generated/`, { method: 'POST', body: formData })
                        .then((response) => response.json())
                        .then((data) => {
                            localStorage.setItem('notificationIdFetch', JSON.stringify(true));
                            handleDenyPermission();
                        })
                        customClose();
                }
            } catch (err) {
                console.log("error", err)
                setOpen(isOpen)
            }
        }else{
            setOpen(isOpen)
        }
    }, [])

    const handleGrantPermission = () => {
        setIsGrantingPermission(true);
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                getToken(messaging, {
                    vapidKey: 'BPxFcVHjN1ZoryfV8K58TnEa5tCL7MpbpB8_Cv6jANEmrv6C_2nQ907IHcQFpuH7Utzn5B_amswRhLl611KZ16U'
                }).then((currentToken) => {
                    if (currentToken) {
                        // encode fcm token
                        var encodedFcm = btoa(currentToken)

                        // set encodedFcm to localStorage
                        localStorage.setItem('baseNotification', encodedFcm)

                        const formData = new FormData();
                        formData.append('device_token', currentToken)
                        formData.append('table_unique_num', localStorage.getItem('table_id'))
                        fetch(`${API_URL}/api/allfunctionuser/device_token_generated/`, { method: 'POST', body: formData })
                            .then((response) => response.json())
                            .then((data) => {
                                localStorage.setItem('notificationIdFetch', JSON.stringify(true));
                                handleDenyPermission();
                            })
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                        handleDenyPermission();
                        notificationDialog();
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token: ', err);
                    handleDenyPermission();
                    // notificationDialog();
                    localStorage.setItem('notificationIdFetch', JSON.stringify(true));

                });
            } else {
                console.log('Unable to get permission to notify.');
                handleDenyPermission();
                notificationDialog();
            }
        });
    };

    const handleDenyPermission = () => {
        customClose();
    };

    const handleDeny = () => {
        localStorage.setItem('notificationIdFetch', JSON.stringify(true));
        customClose();
    }

    return (
        <CustomDialog
            isShow={open}
            handleClose={() => { }}
            icon={<HiOutlineCheck className="h-6 w-6 text-primary-500" aria-hidden="true" />}
            title={t('dialog.notification.allowNotificationTitle')}
            message={t('dialog.notification.allowNotificationMessage')}
            btnText={<>
                {isGrantingPermission ? (
                    <svg className="animate-spin h-5 w-5 mr-1 -ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path
                            className="opacity-75"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4"
                            d="M12 2A10 10 0 0 1 22 12"
                        />
                    </svg>
                ) : (
                    <HiOutlineCheck className="h-5 w-5" aria-hidden="true" />
                )}
                {t('dialog.notification.allowNotificationBtn')}</>}
            btnAction={handleGrantPermission}
            iconColor={'bg-green-100'}
            isDisable={isGrantingPermission}
            btnClose={true}
            btnCloseAction={handleDeny}
            btnCloseText={t('dialog.notification.btnCloseText')}
        />
    )
}

export default NotificationPermission