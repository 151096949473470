import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import StatusChip from '../../modules/custom-components/StatusChip';
import { useEffect, useState } from 'react';

const illustrationEmpty = require('../../assets/images/illustration/emptyIllustration.png')

const CompletedOrder = ({ orderListItems, orderCountCompleted, isOpenHistory, isOpen, setIsOpenHistory }) => {
    const { t } = useTranslation();
    const [data, setData] = useState([])
    
    useEffect(() => {
        if (orderListItems?.completedOrder?.length > 0){
            const filerData = orderListItems?.completedOrder?.reduce((list, item) => {
                if (list[item.product_id]) {
                    list[item.product_id] = { ...list[item.product_id], pro_qty: list[item.product_id].pro_qty + parseInt(item.pro_qty) }
                } else {
                    list[item.product_id] = { ...item, pro_qty: parseInt(item.pro_qty) }
                }
                return list;
            }, {})
            setData(Object.values(filerData || {}))
        }
    }, [orderListItems?.completedOrder])
    
    return (
        <div className="foodOrderCardContainer sm:p-4  ">
            <div className="foodOrderCard border rounded-[15px] relative border-gray-400 border-opacity-50 shadow-md bg-secondary-50 ">
                {orderListItems?.completedOrder.length !== 0 ?
                    <>
                        <div className="orderCardHeader flex justify-between items-center font-semibold   text-sm text-gray-500 px-2 mt-2 ">
                            <div className="third-font">
                                {t('order.noOfItem', { itemCount: orderCountCompleted })}
                            </div>
                            <div className="third-font">
                                {t('order.competedOrderAmount')}{orderListItems?.totalAmt}
                            </div>
                        </div>
                        <Transition
                            show={isOpenHistory}
                            enter="transform transition-all duration-500"
                            enterFrom="max-h-0 opacity-0"
                            enterTo={`max-h-screen opacity-100`}
                            leave="transform transition-all duration-500"
                            leaveFrom={`max-h-screen opacity-100`}
                            leaveTo="max-h-0 opacity-0"
                        >
                            <div className="orderCardBody p-2">
                                <div className="flex flex-col">
                                    <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="overflow-hidden relative">
                                                <table className="min-w-full">
                                                    <thead className="bg-gray-200 border-b">
                                                        <tr>
                                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                {t('order.completedOrderTable.headItem')}
                                                            </th>
                                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                {t('order.completedOrderTable.headQty')}
                                                            </th>
                                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                {t('order.completedOrderTable.headTotal')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='odd:bg-slate-50'>
                                                        {data?.map((item, i) => (
                                                            <tr key={`orderItemTD${item.received_id}`} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                                                {/* <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td> */}
                                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                    {item?.[`name`]}
                                                                </td>
                                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                    {item.pro_qty}
                                                                </td>
                                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                    <StatusChip t={t} status={item.ord_pro_status} />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`absolute left-0 w-full bottom-0 h-[75px] bg-gradient-to-b ${isOpenHistory ? 'hidden' : ''} from-transparent to-[#f5f5f5]`}></div>
                        </Transition>
                        <div className=" mb-1 px-2 mt-1  ">
                            <button className='border text-sm text-gray-500 ml-auto border-gray-300 p-1 text-center flex justify-center items-center rounded-full' onClick={() => setIsOpenHistory(!isOpenHistory)}>
                                {isOpenHistory ? <>
                                    {t('order.btnHide')} <TbChevronUp />
                                </>
                                    : <>
                                        {t('order.btnShow')} <TbChevronDown />
                                    </>
                                }
                            </button>
                        </div>
                    </> :
                    <>
                        <div className="flex flex-col items-center justify-center py-8">
                            <div className="mb-4">
                                <img src={illustrationEmpty} alt="Empty Illustration" className="w-40 h-40" />
                            </div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">{t('order.noCompletedHead')}</h3>
                            <p className="text-gray-500 text-center">{t('order.noCompletedMessage')}
                            </p>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default CompletedOrder