import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    infoLogOutAdmin: false,
};

export const logOutSlice = createSlice({
    name: 'logout',
    initialState,
    reducers: {
        showInfoLogOut: (state, action) => {
            state.infoLogOutAdmin= action.payload;
        }
    }
});

export const { showInfoLogOut } = logOutSlice.actions;

export default logOutSlice.reducer;
