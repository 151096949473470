import {configureStore} from '@reduxjs/toolkit';
import {authSlice} from '../store/reducers/auth';
import {menuSlice} from '../store/reducers/menu';
import {categorySlice} from '../store/reducers/category';
import {productPreviewSlice} from '../store/reducers/productPreview';
import {checkOutSlice} from '../store/reducers/checkOut';
import {categoryShowSlice} from '../store/reducers/categoryShow';
import {searchBarSlice} from '../store/reducers/searchBar';
import {restaurantSlice} from '../store/reducers/restaurant';
import {logOutSlice} from '../store/reducers/logOut';
import {languageSlice} from '../store/reducers/language';
import {createLogger} from 'redux-logger';
import { orderDataSlice } from './reducers/orderData';
import { orderDialogSlice } from './reducers/orderDialog';
import { filterSlice } from './reducers/filter';
import { viewModeSlice } from './reducers/viewMode';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    menu: menuSlice.reducer,
    productPreview: productPreviewSlice.reducer,
    checkOut: checkOutSlice.reducer,
    categoryShow: categoryShowSlice.reducer,
    searchBar: searchBarSlice.reducer,
    restoInformation: restaurantSlice.reducer,
    logOut: logOutSlice.reducer,
    language: languageSlice.reducer,
    category: categorySlice.reducer,
    orderData: orderDataSlice.reducer,
    orderDialog: orderDialogSlice.reducer,
    filter: filterSlice.reducer,
    viewMode: viewModeSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware().concat(createLogger())
  ]
});

export default store;
