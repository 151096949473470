import React from 'react';
import { HiExclamation } from 'react-icons/hi';
import CustomDialog from '../../modules/dialog/CustomDialog';
import { t } from 'i18next';

const CameraGenericError = ({ isShow, handleClose }) => {
    const handleReload = () => {
        window.location.reload();
    };

    return (
        <CustomDialog
            isShow={isShow}
            handleClose={handleClose}
            icon={<HiExclamation className="h-6 w-6 text-red-600" aria-hidden="true" />}
            title={t('dialog.login.cameraGenericErrorTitle')}
            message={t('dialog.login.cameraGenericErrorMessage')}
            btnText={t('dialog.login.cameraGenericErrorBtn')}
            btnAction={handleReload}
            btnClose={true}
        />
    )
}

export default CameraGenericError