export const addWindowClass = (classList) => {
    const window =
      document && document.getElementById('root');
    if (window) {
      // @ts-ignore
      window.classList.add(classList);
    }
  };
  
  export const removeWindowClass = (classList) => {
    const window =
      document && document.getElementById('root');
    if (window) {
      // @ts-ignore
      window.classList.remove(classList);
    }
  };