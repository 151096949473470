import React, { useEffect, useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import { CgShoppingBag } from 'react-icons/cg'
import { BsCaretRightFill, BsCaretDownFill } from 'react-icons/bs'
import { TbMinus, TbPlus } from 'react-icons/tb';
import { increaseCartItemQuantity, decreaseCartItemQuantity } from '../../store/reducers/menu'
import { showCheckOut } from '../../store/reducers/checkOut'
import { useDispatch, useSelector } from 'react-redux'
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

const MEDIA_URL = process.env.REACT_APP_MEDIA_URL

const CartItems = ({ data }) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const dispatch = useDispatch()

    const handleDecrement = () => {
        dispatch(decreaseCartItemQuantity(data.product_id))
    }

    const handleIncrement = () => {
        dispatch(increaseCartItemQuantity(data.product_id))
    }
    return (
        <div className="flex items-start w-full px-3 py-3">
            <img
                className="w-12 h-12 rounded-lg object-cover mr-2 shadow"
                src={`${MEDIA_URL}/${data?.img1}`}
                alt=""
            />
            <div className="flex w-full justify-between secondary-font">
                <div className="mr-4">
                    <h2 className="text-base  font-semibold text-slate-900 -mt-1 leading-5 capitalize">
                        {data[`${lang}_pro`]}
                    </h2>
                    <p className="text-slate-600 font-semibold text-sm mt-1">&#8377;{data.product_price}</p>
                </div>
                <div className='flex flex-col '>
                    <div className="w-[5rem] py-1 px-2 text-sm text-primary-500 bg-[#f8fcf9] border border-primary-500 font-semibold secondary-font  rounded-lg flex justify-between items-center gap-1">
                        <button className="decrease" onClick={handleDecrement} >
                            <TbMinus />
                        </button>
                        <span className="qtyCount font-semibold text-primary-600 ">
                            {data.item_qty}
                        </span>
                        <button className="increase" onClick={handleIncrement}>
                            <TbPlus />
                        </button>
                    </div>
                    <p className="text-slate-600 font-semibold text-sm text-right mt-1">&#8377;{parseInt(data.product_price) * data.item_qty}</p>
                </div>
            </div>
        </div>
    )
}

const CartFullViewModal = ({ handleClose, isOpen }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch()
    const { cart, productId } = useSelector(state => ({
        cart: state.menu.cartItem,
        productId: state.menu.menuItemWithId,
    }))
    const [data, setData] = useState([])
    const [totalQty, setTotalQty] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)

    useEffect(() => {
        const cartProduct = [];
        let totalCount = 0;
        let totalAmount = 0;
        cart?.forEach((pId) => {
            Object.values(productId).forEach((pro) => {
                if (pro[pId]) {
                    cartProduct.push(pro[pId])
                    totalCount += pro[pId].item_qty
                    totalAmount+= (+pro[pId].product_price * pro[pId].item_qty)
                }
            })
        })
        setData(cartProduct)
        setTotalQty(totalCount)
        setTotalAmount(totalAmount)

    }, [cart, productId])

    const handleShowCheckOut = () => {
        handleClose()
        dispatch(showCheckOut(true))
        document.body.classList.add('disable-scroll');
    }

    return (
        <>
            <Transition
                key={'cartFullViewOverlay'}
                as={React.Fragment}
                show={isOpen}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div class="fixed z-30 w-full h-full left-0 top-0 inset-0 bg-gray-500 bg-opacity-75 transition-opacity max-w-xl mx-auto" onClick={handleClose}></div>
            </Transition>

            <Transition
                key={'cartFullView'}
                as={React.Fragment}
                show={isOpen}
                enter="transition-transform duration-300 ease-in-out"
                enterFrom="translate-y-full"
                enterTo="-translate-y-full"
                leave="transition-transform duration-300 ease-in-out"
                leaveFrom="-translate-y-full"
                leaveTo="translate-y-full"
            >
                <div style={{ maxHeight: 'calc(100vh - 140px)' }} className="top-full transition-transform duration-400 z-40  fixed rounded-t-2xl ease-material-in bg-white dark:bg-black -translate-y-full w-full max-w-xl mx-auto">
                    <button onClick={handleClose} className="absolute -top-12 p-2 rounded-full bg-black/40 flex justify-center items-center text-xl text-white font-bold left-[50%] -translate-x-[50%]"><RxCross1 size={20} /></button>
                    <div className="w-full z-40 relative" >
                        {/* <div className="absolute w-full h-full left-0 top-0 rounded-t-2xl" /> */}
                        <div className="flex relative justify-between items-center w-full overflow-hidden px-3 h-14 border-b border-gray-200">
                            <div className=" left ">
                                <div className='flex justify-start items-center gap-1 '>
                                    <span className='text-slate-700 text-3xl'>
                                        <CgShoppingBag />
                                    </span>
                                    <span onClick={handleClose} className='text-slate-600 text-md flex items-center gap-x-1'> {totalQty} {t('cart.cartFullHead')} | &#8377;{totalAmount} </span>
                                </div>
                            </div>

                            {/* <div className=" right ">
                                <button
                                    className="text-slate-400 inline-flex space-x-1 rtl:!space-x-reverse justify-center items-center cursor-pointer select-none relative z-10 h-full max-h-12 px-3 text-sm"
                                >
                                    Clear All
                                </button>
                            </div> */}
                        </div>
                    </div>
                    <div className="text-sm z-10 relative overflow-y-auto bg-red-500" style={{ maxHeight: 'calc(52vh)' }}>
                        <div className="flex flex-col bg-white w-full ">
                            {data.map((d, i) => (
                                <CartItems key={`cartFullViewItem${i}`} data={d} />
                            ))}
                        </div>

                    </div>
                    <div className=" my-1 min-h-[50px] px-3 flex justify-center items-center">
                        {/* <div className='flex justify-start items-center gap-1 '>
                            <span className='text-slate-700 text-3xl'>
                                <CgShoppingBag />
                            </span>
                            <span onClick={handleClose} className='text-slate-600 text-md flex items-center gap-x-1'> {totalQty} Items Added <BsCaretDownFill /> </span>
                        </div> */}
                        <div className='w-full'>
                            <button onClick={handleShowCheckOut}
                                className="flex text-center justify-center items-center appearance-none py-1 transition-colors focus:outline-none cursor-pointer select-none overflow-hidden z-10 w-full relative duration-300 font-medium px-6 rounded-lg text-white bg-primary-600 text-sm h-14"
                                tabIndex={0}
                            >
                                {t('cart.cartFullBtn')} <BsCaretRightFill />
                            </button>

                        </div>
                    </div>
                </div>
            </Transition>
        </>
    )
}

export default CartFullViewModal