import React from 'react'
import { RxLapTimer } from 'react-icons/rx'
import { addToCart, increaseCartItemQuantity, decreaseCartItemQuantity } from '../../../store/reducers/menu'
import { showProductPreview } from '../../../store/reducers/productPreview'
import { useDispatch, useSelector } from 'react-redux'
import { TbMinus, TbPlus } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

const MEDIA_URL = process.env.REACT_APP_MEDIA_URL

const ProductCard = ({ productData }) => {
    const { i18n, t } = useTranslation();
    const lang = i18n.language;
    const dispatch = useDispatch()
    const {mode} = useSelector(state=>({
        mode: state.viewMode.mode
    }))
    const inCart = productData.in_cart || false

    const handleAddTOCart = () => {
        dispatch(addToCart(productData.product_id))
    }

    const handleDecrement = () => {
        dispatch(decreaseCartItemQuantity(productData.product_id))
    }

    const handleIncrement = () => {
        dispatch(increaseCartItemQuantity(productData.product_id))
    }

    const handleShowPreview = () => {
        const type = productData.pro_type === 'veg' ? 'pureVeg' : productData.pro_type === 'nonveg' ? 'nonVeg' : productData.pro_type === 'brg' ? 'beverage' : 'bar'
        dispatch(showProductPreview({ show: true, id: productData.product_id, type: type,mode:mode }))
        // Add the disable-scroll class to the root div (body)
        document.body.classList.add('disable-scroll');
    }

    return (
        <div className=" rounded-xl bg-white p-4 pb-[2.3rem] pt-2 shadow-3 overflow-x-hidden">
            <h3 className="mb-2 text-lg font-medium line-clamp-1">
                <span className="secondary-font text-capitalize select-none"> {productData[`${lang}_pro`]} </span>
            </h3>
            <div className="flex items-start gap-x-2">

                <div className="grid h-[6rem] w-[6rem] shrink-0 place-content-center rounded-xl relative"
                    aria-hidden="true" >
                    <img src={`${MEDIA_URL}/${productData.img1}`} onClick={handleShowPreview} className='object-cover h-[6rem] w-[6rem] absolute top-0  rounded-xl ' alt="" />

                    {!inCart ?
                        <>
                            {mode === 'guest' ?
                                <></>
                                :
                                <button onClick={handleAddTOCart} className="select-none absolute w-[5rem] py-1 px-2 text-lg text-primary-500 bg-[#f8fcf9] border border-primary-500 font-semibold secondary-font bottom-0 translate-y-[50%] rounded-lg left-[0.5rem]">
                                    {t('product.cartBtn')} +
                                </button>
                            }
                        </>
                        :
                        <div className="absolute w-[5rem] py-1 px-2 text-base text-primary-500 bg-[#f8fcf9] border border-primary-500 font-semibold secondary-font bottom-0 translate-y-[50%] rounded-lg left-[0.5rem] flex justify-between items-center gap-1">
                            <button className="select-none decrease" onClick={handleDecrement}>
                                <TbMinus />
                            </button>
                            <span className="qtyCount">
                                {productData?.item_qty || 1}
                            </span>
                            <button className="select-none increase" onClick={handleIncrement}>
                                <TbPlus />
                            </button>
                        </div>
                    }
                </div>
                <div className='select-none'>
                    <p className="mt-1 text-sm text-gray-700 line-clamp-2 third-font" onClick={handleShowPreview}>
                        {productData?.[`${lang}_desc_pro`]}
                    </p>
                    <div className="mt-2 flex flex-col ">
                        <p className=" text-xs text-gray-500 flex items-center ms-1 whitespace-nowrap third-font">
                            {productData?.[`${lang}_category_name`]}
                            <span className=" block font-bold mx-1 text-sm" aria-hidden="true">&middot;</span>
                            {/* <span className='font-normal text-xs'>
                                <FaIndianRupeeSign/>
                            </span> */}
                            &#8377;{parseInt(productData.product_price)} {t('product.countMember')}
                        </p>
                        <div className="flex mt-2 items-center justify-between gap-1">
                            <div className='flex items-center text-gray-500 gap-1 third-font'>
                                <RxLapTimer className='text-primary-500' />
                                <p className="text-xs">20-30 min</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCard