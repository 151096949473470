import React from 'react';
import { HiExclamation } from 'react-icons/hi';
import { useNavigate } from "react-router-dom";
import CustomDialog from '../../modules/dialog/CustomDialog';
import { t } from 'i18next';

const ScanErrorDialog = ({ isError, handleClose }) => {
    const history = useNavigate();

    const handRedirect = ()=>{
        history('/viewMenu/guest')
    }
    return (
        <CustomDialog 
            isShow={isError} 
            handleClose={handleClose}
            icon={<HiExclamation className="h-6 w-6 text-red-600" aria-hidden="true" />}
            title={t('dialog.login.scanErrorTitle')}
            message={t('dialog.login.scanErrorMessage')}
            btnText={t('dialog.login.scanErrorBtn')}
            btnClose={true}
            btnCloseText={t('dialog.login.scanErrorCloseBtn')}
            btnAction={handRedirect}
        />
    );
};

export default ScanErrorDialog;
