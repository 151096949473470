import { useState } from 'react'
import { Transition } from '@headlessui/react';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { BiSolidMessageSquareDetail } from "react-icons/bi";

const Comments = ({ orderComment,className }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false)

    const handleDrop = () => {
        setIsOpen(pre => !pre);
    }

    const formateDate = (inputDate) => {
        const date = new Date(inputDate);

        const formattedDate = new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }).format(date);

        return formattedDate;
    }

    return (
        <div className={`foodOrderCardContainer sm:p-4 pt-4 transition-all ${className}`}>
            <div className="foodOrderCard border rounded-[15px] transition-all relative border-gray-400 border-opacity-50 shadow-md bg-secondary-50 ">
                <div className="orderCardHeader px-2 border-b-2 overflow-hidden text-ellipsis">
                    <div className="max-w-xl flex justify-between items-center mx-auto my-3">
                        <h5 className='text-lg text-slate-700 flex items-center gap-2 text-secondary'>
                            <BiSolidMessageSquareDetail size={30} />
                            <span>Comments</span>
                        </h5>
                        <div className=" w-6 h-6 flex pb-1 justify-center items-center rounded-full third-font bg-primary-400/20 text-primary-400 text-xs">
                            {orderComment.length}
                        </div>
                    </div>

                </div>
                <Transition
                    show={isOpen}
                    enter="transform transition-all duration-500"
                    enterFrom="max-h-0 opacity-0"
                    enterTo={`max-h-screen opacity-100`}
                    leave="transform transition-all duration-500"
                    leaveFrom={`max-h-screen opacity-100`}
                    leaveTo="max-h-0 opacity-0"
                >
                    <div className="orderCardBody p-2 bg-gray-100">
                        <div className="flex flex-col">
                            <div className="sm:mx-0.5 lg:mx-0.5">
                                <div className="py-2 inline-block min-w-full px-2 sm:px-6 lg:px-8 overflow-y-auto max-h-[97vh] style-scroll">
                                    <div className="overflow-visible flex flex-col gap-2">

                                        {orderComment.map((com, i) => (
                                            <div key={`comment_item_${i}`} className="bg-white flex flex-col gap-1 rounded-lg p-4">
                                                <div className="text-sm third-font text-blue-500 relative font-thin">
                                                    <time datetime={com?.created_date}>
                                                        {formateDate(com?.created_date)}
                                                    </time>
                                                    <span class="top-0 -left-[22px] absolute translate-y-[25%] w-3.5 h-3.5 bg-blue-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                                                </div>

                                                <p className="text-base secondary-font text-slate-700 font-thin capitalize">
                                                    {com?.ord_comment}
                                                </p>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
                <div className="flex justify-end mb-1 items-center text-xs   text text-gray-500 px-2 mt-1  ">
                    <button className='border text-sm text-gray-500  border-gray-300 p-1 text-center flex justify-center items-center rounded-full' onClick={handleDrop}>
                        {isOpen ? <>
                            {t('order.btnHide')} <TbChevronUp />
                        </>
                            : <>
                                {t('order.btnShow')} <TbChevronDown />
                            </>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Comments