import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    categoryFilter: null,
    searchFilter: null,
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setCategoryFilter: (state, action) => {
            const filter = action.payload
            state.categoryFilter= filter;
        },
        setSearchFilter: (state, action) => {
            const filter = action.payload
            state.searchFilter= filter;
        }
    }
});

export const { setCategoryFilter, setSearchFilter } = filterSlice.actions;

export default filterSlice.reducer;
