import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isCheckOutShow: false,
};

export const checkOutSlice = createSlice({
    name: 'checkOut',
    initialState,
    reducers: {
        showCheckOut: (state, action) => {
            state.isCheckOutShow= action.payload;
        }
    }
});

export const { showCheckOut } = checkOutSlice.actions;

export default checkOutSlice.reducer;
