import React, { useState, useEffect } from 'react';
import { BiFoodMenu } from 'react-icons/bi'
import { IoFastFoodOutline } from 'react-icons/io5'
import { TiContacts } from 'react-icons/ti'
import { TbInfoCircle, TbLogout } from 'react-icons/tb'
import { TfiMore } from 'react-icons/tfi'
import { Transition } from '@headlessui/react';
import { Link } from "react-router-dom";
import LogoutDialog from '../../dialogs/log-out/LogoutDialog';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const BottomTab = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false)
  const { t } = useTranslation();
  const {orderCountTotal} = useSelector(state => ({
    orderCountTotal:state.orderData.orderCountTotal
  }))

  // Get the current location object
  const location = useLocation();

  // Access the pathname (current path) from the location object
  const currentPath = location.pathname;

  const toggleDropdown = () => {
    setIsOpen(prev => {
      if (!prev) {
        document.body.classList.add('disable-scroll');
      } else {
        document.body.classList.remove('disable-scroll');
      }
      return !prev
    });

  };


  const hanleOpenLogout = () => {
    setIsLogout(true)
    toggleDropdown()
  }
  const hanleCloseLogout = () => {
    setIsLogout(false)
  }

  return (
    <>
      <Transition
        key={'menuDropdownOverlay'}
        as={React.Fragment}
        show={isOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div onClick={toggleDropdown} className='w-screen h-screen fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity top-0 left-0 z-20 max-w-xl mx-auto'></div>
      </Transition>

      <div className={`w-full z-20 bottomTabBar transition-all pt-safe text-[16px] text-md-light-on-surface dark:text-md-dark-on-surface fixed max-w-xl`}>
        <div className={`absolute w-full h-full left-0 top-0 bg-white shadow-3`} >
        </div>
        <div className="flex relative items-center w-full  justify-start h-16 pl-safe pr-safe">
          {/* nav - start */}
          <nav className="sticky bottom-0 mx-auto flex w-full justify-between gap-8 bg-white px-10 pt-2 pb-1 text-xs sm:max-w-md sm:rounded-t-xl  sm:text-sm">
            <Link to={'/'} className={`flex flex-col items-center gap-1 ${currentPath === '/'? 'text-primary-600':'text-gray-400'}  transition duration-100`}>
              <BiFoodMenu size={26} />
              <span className='third-font'>{t('bottomBar.menu')}</span>
            </Link>
            <Link to={'/order'}
              className={`flex relative flex-col items-center gap-1 ${currentPath === '/order'? 'text-primary-600':'text-gray-400'}  transition duration-100`}
            >
              <IoFastFoodOutline size={24} />
              <span>{t('bottomBar.order')}</span>
              {orderCountTotal > 0&&
                <div class="absolute inline-flex items-center justify-center w-4 h-4 font-bold text-white bg-red-500 border-1 border-white rounded-full -top-2 -right-2 dark:border-gray-900" style={{fontSize:'10px'}}>{orderCountTotal}</div>
              }
            </Link>
            <Link to={'/contactUs'}
              className={`flex flex-col items-center gap-1 ${currentPath === '/contactUs'? 'text-primary-600':'text-gray-400'}  transition duration-100`}
            >
              <TiContacts size={24} />
              <span>{t('bottomBar.contact')}</span>
            </Link>

            <div
              className="relative"
            >
              <button onClick={toggleDropdown} className={`flex flex-col items-center gap-1 ${isOpen? 'text-primary-600':'text-gray-400'}  transition duration-100`}>
                <TfiMore size={24} />
                <span>{t('bottomBar.more')}</span>
              </button>

              <Transition
                as={React.Fragment}
                key={'menuDropdown'}
                show={isOpen}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="origin-top-right absolute right-0 -top-[108px] mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  {/* Dropdown content */}
                  <div className="py-1" role="menu" >
                    <a onClick={toggleDropdown} href="https://fudly.in" target='_blank' rel="noreferrer" className="flex gap-x-2 w-full text-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                      <TbInfoCircle size={20} /> {t('bottomBar.moreOption.about')}
                    </a>
                    <button onClick={hanleOpenLogout} className="flex gap-x-2 w-full text-start px-4 py-2 text-sm text-red-700 hover:bg-gray-100 hover:text-red-900" role="menuitem">
                      <TbLogout size={20} /> {t('bottomBar.moreOption.logout')}
                    </button>
                  </div>
                </div>
              </Transition>

            </div>
          </nav>
          {/* nav - end */}
        </div>
      </div>
      <LogoutDialog isShow={isLogout} handleClose={hanleCloseLogout} />
    </>

  )
}

export default BottomTab