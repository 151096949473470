import React from 'react';
import { HiExclamation } from 'react-icons/hi';
import CustomDialog from '../../modules/dialog/CustomDialog';
import { t } from 'i18next';

const CameraError = ({isShow,handleClose}) => {
    const handleReload = () => {
        window.location.reload();
    };
  return (
    <CustomDialog 
            isShow={isShow} 
            handleClose={handleClose}
            icon={<HiExclamation className="h-6 w-6 text-red-600" aria-hidden="true" />}
            title={t('dialog.login.cameraErrorTitle')}
            message={t('dialog.login.cameraErrorMessage')}
            btnText={t('dialog.login.cameraErrorBtn')}
            btnAction={handleReload}
            btnClose={true}
        />
  )
}

export default CameraError