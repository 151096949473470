const StatusChip = ({ status,t }) => {
  let colorClasses = '';
  let backgroundClasses = '';
  let statusText = '';

  // Set the color and background classes based on the status
  switch (status) {
    case 'pending':
      colorClasses = 'text-orange-700';
      backgroundClasses = 'bg-orange-200/30';
      statusText = t('order.orderStatus.pending')
      break;
    case 'inprogress':
      colorClasses = 'text-blue-700';
      backgroundClasses = 'bg-blue-200/30';
      statusText = t('order.orderStatus.inprogress')

      break;
    case 'dispatch':
      colorClasses = 'text-yellow-800';
      backgroundClasses = 'bg-yellow-200/30';
      statusText = t('order.orderStatus.dispatch')
      break;
    case 'completed':
      colorClasses = 'text-green-700';
      backgroundClasses = 'bg-green-200/30';
      statusText = t('order.orderStatus.completed')
      break;
    case 'cancel':
      colorClasses = 'text-gray-700';
      backgroundClasses = 'bg-gray-200/30';
      statusText = t('order.orderStatus.cancel')
      break;
    default:
      break;
  }

  return (
    <span className={`inline-block px-3 py-1 text-sm ${colorClasses} ${backgroundClasses} rounded-full`}>
      {statusText}
    </span>
  );
};

export default StatusChip;
