import React from 'react';
import { HiExclamation } from 'react-icons/hi';
import CustomDialog from '../../modules/dialog/CustomDialog';
import { t } from 'i18next';

const NotificationRequest = ({ isOpen, handleClose }) => {
    const handleReload = () => {
        window.location.reload();
    };
    return (
        <CustomDialog
            isShow={isOpen}
            handleClose={handleClose}
            icon={<span role="img" aria-label="Notification Emoji" className="text-2xl">📢</span>}
            title={t('dialog.notification.requestTitle')}
            message={t('dialog.notification.requestMessage')}
            btnText={t('dialog.notification.requestBtn')}
            btnAction={handleReload}
            btnClose={true}
            iconColor={'bg-yellow-100'}
        />
    )
}

export default NotificationRequest