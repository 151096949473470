import React, { useRef, useState, useEffect, useMemo } from "react";
import { TbArrowBack, TbDownload, TbPrinter } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";


const Invoice = ({
  restoInfo,
  formattedDate,
  invoiceData,
  orderMainId
}) => {
  const invoiceRef = useRef(null);

  const orderListItems = useMemo(() => {
    if (invoiceData['product_order_list']?.length > 0) {
      const reduceProduct = invoiceData['product_order_list']?.reduce((list, item) => {
        if (list[item.eng_pro]) {
          list[item.eng_pro] = { ...list[item.eng_pro], pro_qty: list[item.eng_pro].pro_qty + parseInt(item.pro_qty) }
        } else {
          list[item.eng_pro] = { ...item, pro_qty: parseInt(item.pro_qty) }
        }
        return list;
      }, {})
      return Object.values(reduceProduct || {})?.map((order, index) => (
        <tr key={`orderItemTD-${index}`} className="">
          <td>{index + 1}.</td>
          <td>
            {order?.eng_pro}
          </td>
          <td>{order.pro_qty}</td>
          <td>&#8377;{order.pro_price}</td>
          <td>{((order.pro_price * order.pro_qty) || 0)?.toFixed(2)}</td>
        </tr>
      ));
    }
    return null;
  }, [invoiceData]);
  return (
    <>
      <link rel="stylesheet" href={require("../assets/style/invoice.css")} />
      <link rel="stylesheet" href={require("../assets/style/print.css")} />
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
        crossOrigin="anonymous"
      />
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"
        rel="stylesheet"
      ></link>
      <section className="main h-100 ">
        <div
          className="page-content container position-relative h-100"
          style={{ paddingBottom: '6rem' }}
          ref={invoiceRef}
          id="section-to-print"
        >
          <div className="page-header text-blue-d2 d-flex justify-content-between flex-column flex-sm-row">
            <h1 className="page-title h1 text-secondary-d1 mb-2 mb-sm-0">
              Invoice
              {/* <small className="page-info">
                <i className="fa fa-angle-double-right text-80"></i>
                
              </small> */}
            </h1>
            <div className="text-150 flex items-center gap-1">
              <img src={restoInfo?.resto_logo} className="w-14 h-auto" alt="" />{" "}
              <div className="flex flex-col items-start">
                <span className="h2 m-0">
                  {restoInfo?.resto_name}
                </span>
                <span className="text-xs text-slate-700">
                  GSTIN {restoInfo?.resto_gst}
                </span>

              </div>
            </div>
          </div>

          <div className="container px-0">
            <div className="row mt-2">
              <div className="col-12 col-lg-12">
                <hr className="row brc-default-l1 mx-n1 mb-4" />

                <div className="row">
                  <div className="col-sm-6">
                    <div>
                      <span className=" text-grey-m2 align-middle">Invoice to:</span>
                      <span className="text-600 text-110 text-blue align-middle capitalize"> {invoiceData?.cust_name} </span>
                    </div>
                    <div className="text-grey-m2">
                      <div className="my-1">
                        Payment Mode
                        :{" "}
                        <span className="font-bold capitalize">
                          {invoiceData?.payment_mode}
                        </span>
                      </div>
                      {invoiceData?.cust_mob &&
                        <div className="my-1">
                          <i className="fa fa-phone fa-flip-horizontal text-secondary"></i>{" "}
                          <b className="text-600">{invoiceData?.cust_mob}</b>
                        </div>
                      }
                      {invoiceData?.cust_gstno &&
                        <div className="my-1">
                          GSTIN
                          :{" "}
                          <span className="font-bold capitalize">
                            {invoiceData?.cust_gstno}
                          </span>
                        </div>
                      }
                    </div>
                  </div>

                  <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                    <hr className="d-sm-none" />
                    <div className="text-grey-m2">
                      <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                        Invoice
                      </div>

                      <div className="my-2">
                        <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                        <span className="text-600 text-90">
                          ID
                        </span>{" "}
                        #{invoiceData?.pay_id}-{orderMainId}-23
                      </div>

                      <div className="my-2">
                        <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                        <span className="text-600 text-90">
                          Invoice Date
                          :
                        </span>{" "}
                        <span id="issue_date"> {formattedDate} </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <div className="">
                    <table className="table table-striped table-borderless border-0 border-b-2 brc-default-l1">
                      <thead className="bg-none bgc-default-tp1">
                        <tr className="text-white">
                          <th className="opacity-2">#</th>
                          <th>Name</th>
                          <th>Qty</th>
                          <th>@Unit</th>
                          <th>Amount (+ GST)</th>
                        </tr>
                      </thead>

                      <tbody
                        className=" text-95 text-secondary-d3"
                        id="invoice_container"
                      >
                        {orderListItems}
                      </tbody>
                    </table>
                  </div>

                  <div className="row mt-3 justify-end">
                    <div className="col-12 col-sm-6 text-grey text-90">
                      <div className="secondBlock border border-1 rounded-lg ">
                        <h6 className="text-center mt-1 underline">GST Calculation</h6>
                        <div className="row my-2">
                          <div className="col-7 text-right">
                            Base Price <span className="text-xs text-gray-500 ">(Without GST)</span>
                          </div>
                          <div className="col-5 text-right pe-4">
                            <span className="text-110 text-secondary-d1" id="cgst_amt">&#8377;{parseFloat(invoiceData?.base_price || 0)?.toFixed(2)}</span>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-7 text-right">
                            Round Off
                          </div>
                          <div className="col-5 text-right pe-4">
                            <span className="text-110 text-secondary-d1" id="sgst_amt">(- &#8377;{invoiceData?.cust_ajustment_amt})</span>
                          </div>
                        </div>

                        <div className="row my-2 align-items-center bgc-primary-l3 p-2">
                          <div className="col-7  font-bolder p-0 text-right">
                            After Round Off
                          </div>
                          <div className="col-5 text-right pe-3">
                            <span className="font-bolder  opacity-2" id="g_total">
                              &#8377;{(parseFloat(invoiceData?.base_price || 0) - parseFloat(invoiceData?.cust_ajustment_amt || 0))?.toFixed(2)}
                            </span>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-7 text-right">
                            CGST({(parseFloat(invoiceData?.gst_rate || 5) / 2)?.toFixed(1)}%)
                          </div>
                          <div className="col-5 text-right pe-4">
                            <span className="text-110 text-secondary-d1" id="cgst_amt">&#8377;{(parseFloat(invoiceData?.gst_amount || 0) / 2)?.toFixed(2)}</span>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-7 text-right">
                            SGST({(parseFloat(invoiceData?.gst_rate || 5) / 2)?.toFixed(1)}%)
                          </div>
                          <div className="col-5 text-right pe-4">
                            <span className="text-110 text-secondary-d1" id="sgst_amt">&#8377;{(parseFloat(invoiceData?.gst_amount || 0) / 2)?.toFixed(2)}</span>
                          </div>
                        </div>

                      </div>
                      <div className="img justify-center hidden sm:flex ">
                        <img src={require('../assets/images/illustration/paid_stamp.png')} className="w-24 mt-8 h-24" alt="" srcset="" />
                      </div>

                    </div>

                    <div className="col-12 col-sm-6 text-grey text-90 mt-2 mt-sm-0 ">

                      <div className="firstBlock border border-1 rounded-lg">
                        <h6 className="text-center mt-1 underline">Amount Calculation</h6>
                        <div className="row my-2">
                          <div className="col-7 text-right">
                            SubTotal 
                            {/* {invoiceData?.resto_gst_type === 'inclusive' ? '(+GST)' : ''} */}
                          </div>
                          <div className="col-5 text-right pe-4">
                            <span className="text-120 text-secondary-d1" id="sub_total">&#8377;{(parseFloat(invoiceData?.base_price || 0) - parseFloat(invoiceData?.cust_ajustment_amt || 0)).toFixed(2)}</span>
                          </div>
                        </div>

                        <div className="row my-2">
                          <div className="col-7 text-right">
                            Tax ({(parseFloat(invoiceData?.gst_rate || 5))?.toFixed(1)}%)
                          </div>
                          <div className="col-5 text-right pe-4">
                            <span className="text-110 text-secondary-d1" id="service_amt">&#8377;{parseFloat(invoiceData?.gst_amount || 0)?.toFixed(2)}</span>
                          </div>
                        </div>

                        {invoiceData?.resto_service_charge !== '0' &&
                          <div className="row my-2">
                            <div className="col-7 text-right">
                              Service Charge ({(parseFloat(invoiceData?.rate_rate || 10) / 2)?.toFixed(1)}%)
                            </div>
                            <div className="col-5 text-right pe-4">
                              <span className="text-110 text-secondary-d1" id="service_amt">&#8377;{parseFloat(invoiceData?.service_price || 0)?.toFixed(2)}</span>
                            </div>
                          </div>
                        }

                        <div className="row my-2 align-items-center bgc-primary-l3 p-2">
                          <div className="col-7  font-bolder p-0 text-right">
                            Amount to be paid
                          </div>
                          <div className="col-5 text-right pe-3">
                            <span className="font-bolder  opacity-2" id="g_total">
                              &#8377;{parseFloat(invoiceData?.grand_total)?.toFixed(2)}
                            </span>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-7 text-right">
                            Pay ({invoiceData?.payment_mode})
                          </div>
                          <div className="col-5 text-right pe-4">
                            <span className="text-110 text-secondary-d1" id="sgst_amt">&#8377;{parseFloat(invoiceData?.grand_total)?.toFixed(2)}</span>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-7 text-right">
                            Due Amount
                          </div>
                          <div className="col-5 text-right pe-4">
                            <span className="text-110 text-secondary-d1" id="sgst_amt">&#8377;{0.00}</span>
                          </div>
                        </div>

                      </div>
                      <div className="img justify-center flex sm:hidden ">
                        <img src={require('../assets/images/illustration/paid_stamp.png')} className="w-24 mt-8 h-24" alt="" srcset="" />
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <hr />
          <p className="text-center text-gray-700 text-sm">
            Thank you for dining with us!
          </p>
          <div id="footer_greet">
            <div className="bg-gray-200 py-2">
              <p className="text-center text-gray-700 text-sm" style={{ marginTop: '1rem' }}>
                {/* <span className=" text-primary-500 underline font-semibold italic text-sm">
                          {restoInfo?.resto_name}
                        </span>{" "}
                        &nbsp;&nbsp;&nbsp;{" "} */}
                Phone:
                {restoInfo?.resto_mobile} &nbsp;&nbsp;&nbsp;{" "}
                Email:
                {restoInfo?.resto_email} &nbsp;&nbsp;&nbsp;<br />
                Address: {restoInfo?.resto_address}
              </p>


            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const API_URL = process.env.REACT_APP_API_URL;

export default function OrderDone({ checkSession }) {
  const { invoiceId } = useParams();
  const invoiceContainerRef = useRef(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [restInfoLocal, setRestInfoLocal] = useState({});
  const [orderMainId, setOrderMainId] = useState('');
  const [invoiceData, setInvoiceData] = useState({});
  const history = useNavigate();

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  useEffect(() => {
    if (!!invoiceId) {
      try {
        let invoiceDecId = atob(`${invoiceId}`).split("-");
        const [table_id, order_id] = invoiceDecId;
        setOrderMainId(order_id)
        if (!!table_id) {
          fetch(`${API_URL}/payment/${order_id}`, { method: "GET" })
            .then((response) => response.json())
            .then((data) => {
              if (data.status) {
                setIsAuthorized(true);
                const restoData = data['payment_list']
                setRestInfoLocal({
                  ...restoData,
                  resto_logo: `https://terbaiks.com/demos/fudly/uploads/user_logo/${restoData.resto_logo}`,
                  resto_name: restoData.resto_name,
                  // resto_table: restoData.allot_table_name_number,
                  resto_gst: restoData.resto_gstno,
                  resto_address: restoData.resto_address,
                  resto_email: restoData.resto_email,
                  resto_mobile: restoData.resto_mobile,
                });
                setInvoiceData(restoData)
              } else {
                history("/home");

              }
            })
        }
      } catch {
        history("/home");
        console.log(invoiceId, "erroro");
      }
    }
  }, []);

  useEffect(() => {
    if (
      Object.keys(invoiceData).length &&
      !!invoiceData?.product_order_list?.length
    ) {

      localStorage.setItem("order_id", null);
      localStorage.setItem("table_id", null);
      localStorage.setItem("login", false);
      const language = localStorage.getItem("language");
      const localFcm = localStorage.getItem("baseNotification");
      // Clear the localStorage
      localStorage.clear();

      // Set the language preference back to the localStorage
      localStorage.setItem("language", language);
      localStorage.setItem("baseNotification", localFcm);

      checkSession()
    } else {
      console.log("no order data");
    }
  }, [invoiceData]);

  const downloadPDF = () => {

    // Scroll to the top of the invoice container
    if (invoiceContainerRef.current) {
      invoiceContainerRef.current.scrollTo(0, 0);
    }
    setTimeout(() => {
      window.print();
    }, 100);
  };
  const goBackHandle = () => {
    const language = localStorage.getItem("language");
    const localFcm = localStorage.getItem("baseNotification");
    // Clear the localStorage
    localStorage.clear();

    // Set the language preference back to the localStorage
    localStorage.setItem("language", language);
    localStorage.setItem("baseNotification", localFcm);
    window.location.href = "/login";
  };

  return (
    <>
      {isAuthorized && (
        <div className="w-full  max-w-full overflow-x-hidden" id="main" style={{ minHeight: '100vh' }}>
          <div
            ref={invoiceContainerRef}
            id="invoice-container"
            className={`relative mx-auto`}
            style={{
              maxWidth: "21cm",
              minHeight: "29cm",

            }}
          >
            <Invoice
              restoInfo={restInfoLocal}
              invoiceData={invoiceData}
              formattedDate={formattedDate}
              orderMainId={orderMainId}
            />
            <button
              id="printButton"
              className="p-0 px-2 h-12 text-white flex items-center justify-center bg-blue-600 rounded-full hover:bg-blue-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none fixed translate-x-[-50%] bottom-6"
              onClick={downloadPDF}
              style={{ left: "calc(50% + 1.75rem)" }}
            >
              <TbPrinter size={26} /> Print
            </button>
            <button
              id="backButton"
              className="p-0 px-2 h-12 text-white flex items-center justify-center bg-red-600 rounded-full hover:bg-red-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none fixed translate-x-[-50%] bottom-6"
              style={{ left: "calc(50% - 3.90rem)" }}
              onClick={goBackHandle}
            >
              <TbArrowBack size={26} /> Login
            </button>
          </div>
        </div>
      )}
    </>
  );
}
