import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    categoryItemWithId: {},
};

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setCategoryItem: (state, action) => {
            // state.menuItem = action.payload;
            // state.menuItem = action.payload;
            const menu = action.payload

            // Object.keys(menu).forEach((key) => {
            //     state[`categoryItemWithId`][key] = menu[key].reduce((accumulator, item) => {
            //         // state.menuItemWithId[item.product_id] = item;
            //         accumulator[item.category_id] = item;
            //         return accumulator;
            //     }, {});
            // })
            // const category = action.payload
            state.categoryItemWithId = menu
        },
    }
});

export const { setCategoryItem } = categorySlice.actions;

export default categorySlice.reducer;
