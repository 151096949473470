import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  restoInfo: {},
};

export const restaurantSlice = createSlice({
  name: 'restoInformation',
  initialState,
  reducers: {
    setRestInfo: (state, action) => {
      state.restoInfo = action.payload;
    },
  },
});

export const { setRestInfo } = restaurantSlice.actions;

export default restaurantSlice.reducer;