import { useState } from 'react'
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { LuAlignRight } from 'react-icons/lu'
import FoodCategoryChip from '../../components/home/category/FoodCategoryChip';
import SearchBar from '../search/SearchBar'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const TopTabBar = ({ pureVeg, nonVeg, barDrink, beverages }) => {
    const [activeTab, setActiveTab] = useState(0);
    const { emptyTab } = useSelector(state => ({
        emptyTab: state.menu.emptyTab
    }))
    const [t] = useTranslation();
    const handleChangeTab = (index) => {
        setActiveTab(index);
    };
    return (
        <>
            {/* nav - start */}
            <nav className="stickyTab z-20 transition-all sticky mx-auto flex w-full  bg-white tabFilter-shadow  ps-1 text-xs  ">
                <div className="leftTabs flex  justify-between sm:text-sm w-full">
                    {!emptyTab.includes('pureVeg') &&
                        <div onClick={() => handleChangeTab(0)}
                            className={` ${activeTab === 0 ? 'text-primary-500 before:bg-green-600 font-semibold tab-active' : 'text-gray-400'} flex-1 cursor-pointer px-2 py-4 text-center relative `}>
                            <span>{t('topTabBar.pureVeg')}</span>
                        </div>
                    }
                    {!emptyTab.includes('nonVeg') &&
                        <div onClick={() => handleChangeTab(1)}
                            className={` ${activeTab === 1 ? 'text-red-500 before:bg-red-500 font-semibold tab-active' : 'text-gray-400'} flex-1 cursor-pointer px-2 py-4 text-center relative `}
                        >
                            <span>{t('topTabBar.nonVeg')}</span>
                        </div>
                    }
                    {!emptyTab.includes('beverage') &&
                        <div onClick={() => handleChangeTab(2)}
                            className={` ${activeTab === 2 ? 'text-blue-500 before:bg-blue-500 font-semibold tab-active' : 'text-gray-400'} flex-1 cursor-pointer px-2 py-4 text-center relative `}
                        >
                            <span>{t('topTabBar.beverages')}</span>
                        </div>
                    }
                    {!emptyTab.includes('bar') &&
                        <div onClick={() => handleChangeTab(3)}
                            className={` ${activeTab === 3 ? 'text-slate-900 before:bg-slate-900 font-semibold tab-active' : 'text-gray-400'} flex-1 cursor-pointer px-2 py-4 text-center relative `}
                        >
                            <span>{t('topTabBar.bar')}</span>
                        </div>
                    }
                </div>
                {/* <button className="w-[40px] flex justify-center items-center text-xl  text-slate-900 relative before:content-[''] before:absolute before:w-[1px] before:h-[60%] before:left-0 before:top-[20%]  before:bg-slate-400">
                    <LuAlignRight />
                </button> */}
            </nav>
            {/* nav - end */}
            <SwipeableViews disabled={true} key={'tabSwipeable'} index={activeTab} className='sticky transition-all stickySearch z-[19]' onChangeIndex={handleChangeTab} >
                {!emptyTab.includes('pureVeg') &&
                    <div>
                        <SearchBar type={'pureVeg'} />
                        <FoodCategoryChip categoryType={'pureVeg'} />
                    </div>
                }
                {!emptyTab.includes('nonVeg') &&
                    <div>
                        <SearchBar type={'nonVeg'} />
                        <FoodCategoryChip categoryType={'nonVeg'} />
                    </div>
                }
                {!emptyTab.includes('beverage') &&
                    <div>
                        <SearchBar type={'beverages'} />
                        <FoodCategoryChip categoryType={'beverages'} />
                    </div>
                }
                {!emptyTab.includes('bar') &&
                    <div>
                        <SearchBar type={'bar'} />
                        <FoodCategoryChip categoryType={'bar'} />
                    </div>
                }
            </SwipeableViews>

            <SwipeableViews disabled={true} key={'tabContentSwipeable'} index={activeTab} onChangeIndex={handleChangeTab}>
                {!emptyTab.includes('pureVeg') &&
                    <div key={'swipeableView1'} className=''>{activeTab === 0 && <>{pureVeg}</>}</div>
                }
                {!emptyTab.includes('nonVeg') &&
                    <div key={'swipeableView2'} className=''>{activeTab === 1 && <>{nonVeg}</>}</div>
                }
                {!emptyTab.includes('beverage') &&
                    <div key={'swipeableView3'} className=''>{activeTab === 2 && <>{beverages}</>}</div>
                }
                {!emptyTab.includes('bar') &&
                    <div key={'swipeableView4'} className=''>{activeTab === 3 && <>{barDrink}</>}</div>
                }
            </SwipeableViews>
        </>

    )
}

export default TopTabBar