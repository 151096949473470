const API_URL = process.env.REACT_APP_API_URL

export const authLogin = (mobile, password) => {
  const options = {
    method: 'POST',
    body: new URLSearchParams({user_mobile: mobile, user_password: password})
  };
  
  
  return new Promise(async (res, rej) => {
    // await sleep(500);
    // return res({ profile: { mobile: '9876543210', user_type: 'admin',user_id:'22' } });  
    fetch(`${API_URL}/api/login`, options)
    .then(response => response.json())
    .then(response => {
      if (response.status === true){
        if (parseInt(response.response.user_status) === 1){
          localStorage.setItem(
            'authentication',
            JSON.stringify({ profile: { mobile: response.response.user_mobile, user_type: response.response.user_type,user_id:response.response.user_id } })
          );
          return res({ profile: { mobile: response.response.user_mobile, user_type: response.response.user_type,user_id:response.response.user_id } });  
        }else{
          return rej({ message: response.msg,input: null,status:parseInt(response.response.user_status) })
        }
      }else{
        let input = response.status === 'notExist'?'mobile':response.status === 'incorectPassword'?'password':null
        return rej({ message: response.msg,input: input,status:null })
      }
    })
    .catch(err => {
      return rej({ message: err.message,input:null,status:null})
      
    });
    // return rej({ message: 'dwdw are wrong!' });
  });
};


export const getAuthStatus = () => {
  return new Promise(async (res, rej) => {
    //  await sleep(500);
    try {
      let authentication = localStorage.getItem('authentication');
      let tableId = localStorage.getItem('table_id');
      if (authentication && tableId) {
        authentication = JSON.parse(authentication);
        return res(authentication);
      }
      return res(undefined);
    } catch (error) {
      return res(undefined);
    }
  });
};
