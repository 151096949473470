import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isCategoryShow: false,
    categoryType: null,
};

export const categoryShowSlice = createSlice({
    name: 'categoryShow',
    initialState,
    reducers: {
        setCategoryShow: (state, action) => {
            const category = action.payload
            state.isCategoryShow= category.show;
            state.categoryType= category.type;
        }
    }
});

export const { setCategoryShow } = categoryShowSlice.actions;

export default categoryShowSlice.reducer;
