import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orderData: {},
    orderComment: [],
    orderCountPending: 0,
    orderCountCompleted: 0,
    orderCountTotal: 0
  };
  
  export const orderDataSlice = createSlice({
    name: 'orderData',
    initialState,
    reducers: {
      setOrderData: (state, action) => {
        state.orderData = action.payload;
        state.orderComment = action.payload.order_comment;
        state.orderCountPending = action.payload.order_list.reduce((sum, item) => item.ord_pro_status !=='cancel' && item.ord_pro_status !=='completed' ? sum +1 : sum, 0);
        state.orderCountCompleted = action.payload.order_list.reduce((sum, item) => item.ord_pro_status !=='cancel' && item.ord_pro_status ==='completed' ? sum +1 : sum, 0);
        state.orderCountTotal = action.payload.order_list.reduce((sum, item) => item.ord_pro_status !=='cancel'  ? sum +1 : sum, 0);
      }
    },
  });
  
  export const { setOrderData } = orderDataSlice.actions;
  
  export default orderDataSlice.reducer;