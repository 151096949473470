import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';

const GuestRoute = () => {
  const isTableId = localStorage.getItem('table_id');
  // const isTableId = true;
  return isTableId ? <Outlet /> : <Navigate to="/login" />;
};

export default GuestRoute;
