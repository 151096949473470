import { Dialog, Transition } from '@headlessui/react';
import { t } from 'i18next';
import React from 'react';

const CustomDialog = ({ isShow, handleClose, icon, title, message, btnText, btnAction,iconColor='bg-red-100', btnClose = false, isDisable = false, isLoading = false, btnCloseText=null, btnCloseAction=null }) => {
    return (
        <Transition.Root show={isShow} as={React.Fragment}>
            <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto " onClose={handleClose}>
                {/* Dialog overlay */}
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:items-center sm:p-0 ">
                    {/* Overlay background */}
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " />
                    </Transition.Child>

                    {/* Modal content */}
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 w-full">
                            <div className="sm:flex sm:items-start">
                                <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${iconColor} sm:mx-0 sm:h-10 sm:w-10`}>
                                    {icon}
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        {title}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {message}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    disabled={isDisable}
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary-500 text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-primary-400 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => btnAction ? btnAction() : handleClose()}
                                >
                                    {btnText}
                                </button>

                                {btnClose &&
                                    <button
                                        disabled={isDisable}
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={btnCloseAction?btnCloseAction:handleClose}
                                    >
                                        {btnCloseText?btnCloseText:t('dialog.btn.close')}
                                    </button>
                                }
                            </div>
                        </div>
                        {/* {children} */}
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default CustomDialog