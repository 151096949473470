import { Transition } from '@headlessui/react';
import React, { useRef, useState, useEffect } from 'react'
import { BiNotepad, BiDish } from 'react-icons/bi'
import { FiCheckCircle } from 'react-icons/fi'
import { MdPendingActions } from 'react-icons/md';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

const PendingOrder = ({ orderData, orderListItems, orderCountPending, setIsOpen, isOpen }) => {
    const { t } = useTranslation();
    const [cardHeight, setCardHeight] = useState('0');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setCardHeight(`${(parseInt(orderCountPending) * 61) + 51}`)
        setLoading(false)
    }, [])


    const handleDrop = () => {
        setIsOpen(prev => !prev)
    }

    const orderCardBodyRef = useRef(null);
    return (
        <>
            {!loading &&
                <div className="foodOrderCardContainer sm:p-4 transition-all">
                    <div className="foodOrderCard border rounded-[15px] transition-all relative border-gray-400 border-opacity-50 shadow-md bg-secondary-50 ">
                        <div className="orderCardHeader px-2 border-b-2 overflow-hidden text-ellipsis">
                            <div className="max-w-xl mx-auto my-3 pb-2">
                                <div className="flex pb-3 gap-x-1">
                                    <div className="flex-1">
                                    </div>

                                    <div className="flex-1">
                                        <div className={`w-10 h-10 ${orderData.order_status > 1 ? 'bg-primary-500 text-white' : 'bg-primary-200/40 text-gray-500'} border-2 mx-auto rounded-full text-lg flex items-center justify-center`}>
                                            <span className=" text-center w-full flex items-center justify-center"><BiNotepad size={24} /></span>
                                        </div>
                                        <span className='text-xs text-center absolute pt-1'>
                                            {t('order.pendingOrderStatus.received')}
                                        </span>
                                    </div>


                                    <div className="w-1/6 align-center items-center align-middle content-center flex">
                                        <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                                            <div className="bg-primary-500 text-xs leading-none py-1 text-center text-gray-darkest rounded " style={{ width: orderData.order_status > 1 ? '100%' : '0%' }}></div>
                                        </div>
                                    </div>


                                    <div className="flex-1">
                                        <div className={`w-10 h-10 ${orderData.order_status > 1 ? 'bg-primary-500 text-white' : 'bg-primary-200/40 text-gray-500'} border-2 mx-auto rounded-full text-lg  flex items-center`}>
                                            <span className=" text-center w-full flex items-center justify-center"><MdPendingActions size={24} /></span>
                                        </div>
                                        <span className='text-xs text-center absolute pt-1'>
                                            {t('order.pendingOrderStatus.preparing')}
                                        </span>
                                    </div>

                                    <div className="w-1/6 align-center items-center align-middle content-center flex">
                                        <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                                            <div className="bg-primary-500 text-xs leading-none py-1 text-center text-gray-darkest rounded " style={{ width: orderData.order_status > 2 ? '100%' : '0%' }}></div>
                                        </div>
                                    </div>

                                    <div className="flex-1">
                                        <div className={`w-10 h-10 ${orderData.order_status > 2 ? 'bg-primary-500 text-white' : 'bg-primary-200/40 text-gray-500'} border-2 mx-auto rounded-full text-lg flex items-center`}>
                                            <span className=" text-center w-full flex justify-center items-center"><BiDish size={24} /></span>
                                        </div>
                                        <span className='text-xs text-center absolute pt-1'>
                                            {t('order.pendingOrderStatus.dispatch')}
                                        </span>
                                    </div>


                                    <div className="w-1/6 align-center items-center align-middle content-center flex">
                                        <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                                            <div className="bg-primary-500 text-xs leading-none py-1 text-center text-gray-darkest rounded " style={{ width: orderData.order_status > 3 ? '100%' : '0%' }}></div>
                                        </div>
                                    </div>


                                    <div className="flex-1 relative">
                                        <div className={`w-10 h-10 ${orderData.order_status > 3 ? 'bg-primary-500 text-white' : 'bg-primary-200/40 text-gray-500'} border-2 mx-auto rounded-full text-lg flex items-center`}>
                                            <span className=" text-center w-full flex justify-center items-center "><FiCheckCircle size={24} /></span>
                                        </div>
                                        <span className='text-xs text-center absolute pt-1 overflow-hidden text-ellipsis'>
                                            {t('order.pendingOrderStatus.completed')}
                                        </span>
                                    </div>
                                    <div className="flex-1">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Transition
                            show={isOpen}
                            enter="transform transition-all duration-500"
                            enterFrom="max-h-0 opacity-0"
                            enterTo={`max-h-screen opacity-100`}
                            leave="transform transition-all duration-500"
                            leaveFrom={`max-h-screen opacity-100`}
                            leaveTo="max-h-0 opacity-0"
                        >
                            <div className="orderCardBody p-2" ref={orderCardBodyRef}>
                                <div className="flex flex-col">
                                    <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="overflow-hidden overflow-y-auto max-h-[97vh] style-scroll">
                                                <table className="min-w-full">
                                                    <thead className="bg-gray-200 border-b">
                                                        <tr>
                                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                {t('order.pendingOrderTable.headItem')}
                                                            </th>
                                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                {t('order.pendingOrderTable.headQty')}
                                                            </th>
                                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                {t('order.pendingOrderTable.headStatus')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='odd:bg-slate-50  relative '>
                                                        {orderListItems?.pendingOrder}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                        <div className="flex justify-between mb-1 items-center text-xs   text text-gray-500 px-2 mt-1  ">

                            <div className="">
                                {t('order.noOfItem', { itemCount: orderCountPending })}
                            </div>
                            <div className="">
                                <img src={require('../../assets/images/illustration/stopWatch1.gif')} className='w-8 h-8' alt="" />
                            </div>
                            <button className='border text-sm text-gray-500  border-gray-300 p-1 text-center flex justify-center items-center rounded-full' onClick={handleDrop}>
                                {isOpen ? <>
                                    {t('order.btnHide')} <TbChevronUp />
                                </>
                                    : <>
                                        {t('order.btnShow')} <TbChevronDown />
                                    </>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default PendingOrder