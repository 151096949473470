import React,{useEffect,useState} from 'react'
import CustomDialog from '../../modules/dialog/CustomDialog'
import { HiExclamation } from 'react-icons/hi';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCancelOrderList } from '../../store/reducers/orderDialog';

const CanceledOrderItem = () => {
    const dispatch = useDispatch()
    const {cancelOrderList} = useSelector(state => ({
        cancelOrderList: state.orderDialog.cancelOrderList
    }))
    const [numberOfDelOrdItem,setNumberOfDelOrdItem] = useState(0)
    const [itemDeleteDialog,setItemDeleteDialog] = useState(false)

    const handleClose =()=>{
        setItemDeleteDialog(false)
    }

    useEffect(() => {
        let numberOfOrderDeleted = 0;
        let cancelOrderListCopy = { ...cancelOrderList };
    
        Object.keys(cancelOrderList).forEach((key) => {
          if (!cancelOrderListCopy[key]) {
            numberOfOrderDeleted += 1;
            cancelOrderListCopy[key] = true;
          }
        });
    
        if (numberOfOrderDeleted > 0) {
          setNumberOfDelOrdItem(numberOfOrderDeleted);
          setItemDeleteDialog(true);
          dispatch(setCancelOrderList(cancelOrderListCopy));
          localStorage.setItem('cancelOrder', JSON.stringify(cancelOrderListCopy));
        }
      }, [cancelOrderList, dispatch]);

  return (
    <CustomDialog 
            isShow={itemDeleteDialog} 
            handleClose={handleClose}
            icon={<HiExclamation className="h-6 w-6 text-yellow-600" aria-hidden="true" />}
            title={t('dialog.order.cancelOrderItemTitle')}
            message={t('dialog.order.cancelOrderItemMessage', {noOfOrd:numberOfDelOrdItem})}
            btnText={t('dialog.btn.close')}
            btnAction={handleClose}
            iconColor={'bg-yellow-100'}
        />
  )
}

export default CanceledOrderItem