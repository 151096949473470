import React, { useState, useRef, useEffect } from 'react'
import DividerWithName from '../../../modules/custom-components/DividerWithName'
import { useDispatch, useSelector } from 'react-redux'
import { setCategoryFilter, setSearchFilter } from '../../../store/reducers/filter'
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_API_URL

const CategoryCard = ({handleClickCategory,cat,lang,categoryFilter}) => {
    const cardRef = useRef(null);

    // useEffect(()=>{
    //     if(categoryFilter?.id === cat.category_id){
    //         cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //     }
    // },[categoryFilter?.id,cat.category_id])

    return (
        <div ref={cardRef} onClick={() => handleClickCategory(cat?.category_id, cat?.[`${lang}_cat`])} className={`flex-none w-[100px] my-[10px] transition-all rounded-xl ${categoryFilter?.id === cat.category_id ? 'shadow-2 ' : ''}`}>
            <div className="w-full flex flex-col  min-h-[100px] px-1">
                <div className="catImage flex justify-center">
                    <img className='w-[80px] h-[80px] object-contain' src={`${API_URL}/uploads/cat_img/${cat?.img1}`} alt="" />
                </div>
                <div className="select-none line-clamp-2 catName text-center text-sm text-slate-600 secondary-font capitalize">
                    {cat?.[`${lang}_cat`]}
                </div>
            </div>
        </div>
    )
}

const FoodCategory = ({ type }) => {
    const { i18n, t } = useTranslation();
    const lang = i18n.language;
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(null)
    const { categoryItemWithId, searchFilter, categoryFilter } = useSelector(state => ({
        categoryItemWithId: state.category.categoryItemWithId,
        searchFilter: state.filter.searchFilter,
        categoryFilter: state.filter.categoryFilter
    }))

    const handleClickCategory = (id, name = "Category") => {
        setSelected(id)
        if (categoryFilter?.id !== id) {
            dispatch(setCategoryFilter({ type: type, id: id, name: name }))
            if (searchFilter) {
                dispatch(setSearchFilter(null))
            }
        } else {
            setSelected(null)
            dispatch(setCategoryFilter(null))
        }
    }

    const data = categoryItemWithId[type]
    return (
        <div className='mb-6'>

            {/* Category Heading */}
            <DividerWithName style={{ marginBottom: '1rem' }} >
                {t('category.categoryTitle')}
            </DividerWithName>
            {/* ./Category Heading */}

            <div className="categoryContainer overflow-hidden pb-2">
                <div className="mainContent flex overflow-hidden overflow-x-auto hide-scroll pr-2">
                    <div onClick={() => handleClickCategory(categoryFilter?.id)} key={`FullcateAll-1`} className={`flex-none w-[100px] my-[10px] transition-all rounded-xl `}>
                        <div className="w-full flex flex-col  h-[100px]">
                            <div className="catImage flex justify-center">
                                <img className='w-[80px] h-[80px] object-contain' src={require('../../../assets/images/logo/fudly_logo.png')} alt="" />
                            </div>
                            <div className="line-clamp-2 catName text-center text-sm text-slate-600 secondary-font">
                                {t('category.allCategory')}
                            </div>
                        </div>
                    </div>
                    {Object.values(data || {})?.map((cat, index) => (
                        <CategoryCard key={`FullCate-${index}`} handleClickCategory={handleClickCategory} cat={cat} lang={lang} categoryFilter={categoryFilter} />
                    ))}

                </div>
            </div>
        </div>
    )
}

export default FoodCategory