import React, { useEffect, useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import { useDispatch, useSelector } from 'react-redux'
import { Transition } from '@headlessui/react';
import { showLanguage } from '../../store/reducers/language';
import { useTranslation } from 'react-i18next';



const LanguageOptions = () => {
    const { i18n,t } = useTranslation();
    const dispatch = useDispatch()
    const { chooseLanguage } = useSelector(state => ({
        chooseLanguage: state.language.chooseLanguage
    }))

    const [selectedLanguage, setSelectedLanguage] = useState('');

    useEffect(() => {
        setSelectedLanguage(i18n.language)
    }, [])

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
        localStorage.setItem('language',JSON.stringify(event.target.value))
    };

    const handleClose = () => {
        document.body.classList.remove('disable-scroll');
        dispatch(showLanguage(false))
    }

    return (
        <>
            <Transition
                key={'cartFullViewOverlay'}
                as={React.Fragment}
                show={chooseLanguage}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div class="fixed z-30 w-full h-full left-0 top-0 inset-0 bg-gray-500 bg-opacity-75 transition-opacity max-w-xl mx-auto" onClick={handleClose}></div>
            </Transition>

            <Transition
                key={'cartFullView'}
                as={React.Fragment}
                show={chooseLanguage}
                enter="transition-transform duration-300 ease-in-out"
                enterFrom="translate-y-full"
                enterTo="-translate-y-full"
                leave="transition-transform duration-300 ease-in-out"
                leaveFrom="-translate-y-full"
                leaveTo="translate-y-full"
            >
                <div style={{ maxHeight: 'calc(100vh - 140px)' }} className="top-full transition-transform duration-400 z-40  fixed rounded-t-2xl ease-material-in bg-white dark:bg-black -translate-y-full w-full max-w-xl mx-auto">
                    <button onClick={handleClose} className="absolute -top-12 p-2 rounded-full bg-black/40 flex justify-center items-center text-xl text-white font-bold left-[50%] -translate-x-[50%]"><RxCross1 size={20} /></button>
                    <div className="w-full z-40 relative" >
                        {/* <div className="absolute w-full h-full left-0 top-0 rounded-t-2xl" /> */}
                        <div className="flex relative justify-between items-center w-full overflow-hidden px-3 h-14 border-b border-gray-200">
                            <div className=" left ">
                                <span className='text-slate-900 secondary-font text-2xl'>
                                    {t('language.languageTitle')}
                                </span>
                            </div>

                            {/* <div className=" right ">
                                <button
                                    className="text-slate-400 inline-flex space-x-1 rtl:!space-x-reverse justify-center items-center cursor-pointer select-none relative z-10 h-full max-h-12 px-3 text-sm"
                                >
                                    Clear All
                                </button>
                            </div> */}
                        </div>
                    </div>
                    <div className="text-sm z-10 relative overflow-y-auto " style={{ maxHeight: 'calc(52vh)' }}>
                        <div className="flex gap-y-3 flex-col py-2 px-3 bg-white w-full ">
                            <label htmlFor='radioDefault01' className={`w-full p-8 rounded-xl transition-all flex items-center gap-x-2 text-xl secondary-font ${selectedLanguage === 'eng' ? 'bg-blue-50' : 'bg-gray-100'} `}>
                                <input
                                    class="relative form-radio peer focus:ring-0  float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary-600 checked:hover:border-primary-600 checked:focus:border-primary-600 checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary-600 checked:after:bg-primary-600 checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] dark:border-neutral-600 dark:checked:border-primary-600 dark:checked:after:border-primary-600 dark:checked:after:bg-primary-600 "
                                    type="radio"
                                    style={{backgroundColor:'transparent    '}}
                                    value={'eng'}
                                    onChange={handleLanguageChange}
                                    name="flexRadioDefault"
                                    id="radioDefault01"
                                    checked={selectedLanguage === 'eng'}
                                />
                                <span>English</span>
                            </label>
                            <label htmlFor='radioDefault02' className={`w-full p-8 rounded-xl transition-all flex items-center gap-x-2 text-xl secondary-font ${selectedLanguage === 'hin' ? 'bg-blue-50' : 'bg-gray-100'} `}>
                                <input
                                    class="relative form-radio peer float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 focus:ring-0  appearance-none  rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary-600 checked:hover:border-primary-600 checked:focus:border-primary-600 checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary-600 checked:after:bg-primary-600 checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] dark:border-neutral-600 dark:checked:border-primary-600 dark:checked:after:border-primary-600 dark:checked:after:bg-primary-600 "
                                    style={{backgroundColor:'transparent    '}}
                                    type="radio"
                                    name="flexRadioDefault"
                                    value={'hin'}
                                    onChange={handleLanguageChange}
                                    checked={selectedLanguage === 'hin'}
                                    id="radioDefault02" />
                                <span>हिंदी</span>
                            </label>
                        </div>

                    </div>
                </div>
            </Transition>
        </>
    )
}

export default LanguageOptions