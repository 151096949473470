import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cancelOrderList: {},
    isOrderCanceled: false,
    isOrderCanceledItem: false,
    isOrderSuccess: false,
    isOrderUpdate: false
};

export const orderDialogSlice = createSlice({
    name: 'orderDialog',
    initialState,
    reducers: {
        setOrderSuccess: (state, action) => {
            state.isOrderSuccess = action.payload
        },
        setOrderUpdate: (state, action) => {
            state.isOrderUpdate = action.payload
        },
        setCancelOrderList: (state, action) => {
            state.cancelOrderList = action.payload;
        },
        setCompleteOrderCanceled: (state, action) => {
            state.isOrderCanceled = action.payload;
        },
        setOrderCanceled: (state, action) => {
            state.isOrderCanceledItem = action.payload;
        }
    },
});

export const { setOrderSuccess, setOrderUpdate, setCancelOrderList, setCompleteOrderCanceled,setOrderCanceled } = orderDialogSlice.actions;

export default orderDialogSlice.reducer;