import React,{useMemo,useEffect} from 'react'
import TopTabBar from '../modules/nav-bar/TopTabBar';
import { useDispatch, useSelector } from 'react-redux';
import ProductTab from '../components/home/ProductTab';
import { setViewMode } from '../store/reducers/viewMode';

const Home = () => {
    const dispatch = useDispatch()
    // const {cart} = useSelector(state=>({
    //     cart: state.menu.cartBar
    // }))

    useEffect(()=>{
        dispatch(setViewMode('user'))
    },[dispatch])

    // useMemo(() => {
    //     console.log("🚀 ~ file: Home.jsx:12 ~ Home ~ cart:", cart)
    // }, [cart])

    return (
        <div className=''>
            <TopTabBar 
            pureVeg={<ProductTab key={'productTab-1'} type={'pureVeg'} />} 
            nonVeg={<ProductTab key={'productTab-2'} type={'nonVeg'} />} 
            barDrink={<ProductTab key={'productTab-3'} type={'bar'} />} 
            beverages={<ProductTab key={'productTab-4'} type={'beverage'} />} />
        </div>
    )
}


export default Home