import React, { useEffect, useRef, useState } from 'react'
import QrCode from 'jsqr';
import Webcam from 'react-webcam';
import { useDispatch } from 'react-redux';
import { setRestInfo } from '../store/reducers/restaurant';
import { useParams, useNavigate } from "react-router-dom";
import { setAuthentication } from '../store/reducers/auth'
import ScanErrorDialog from '../dialogs/login/ScanErrorDialog';
import CameraGenericError from '../dialogs/login/CameraGenericError';
import CameraError from '../dialogs/login/CameraError';
import ScanTableDialog from '../dialogs/login/ScanTableDialog';
import SubscriptionOver from '../dialogs/login/SubscriptionOver';

const API_URL = process.env.REACT_APP_API_URL;

const Login = ({ hasId, checkSession }) => {
  const dispatch = useDispatch()
  const webcamRef = useRef(null);
  const [scanOpen, setScanOpen] = useState(false)
  const [restoInformation, setRestoInformation] = useState({})
  const [scanning, setScanning] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorTable, setIsErrorTable] = useState(false);
  const [isErrorSubscription, setIsErrorSubscription] = useState(false);
  const [isCameraDenied, setIsCameraDenied] = useState(false);
  const [isCameraError, setIsCameraError] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isFetch, setIsFetch] = useState(false);

  const { id } = useParams(); /*Retrieves the value of the "id" parameter from the current URL.*/
  const history = useNavigate();

  const handleScanWebCam = () => {
    setLoadingPage(true)
    checkSession()
    setTimeout(() => {
      dispatch(setAuthentication(true));
      localStorage.setItem('authentication', true)
      history('/')
      setLoadingPage(false)
    }, 4000)
  }

  const handleClose = () => {
    setIsError(false)
    setIsErrorTable(false)
    setIsErrorSubscription(false)
    setIsCameraDenied(false)
    setIsCameraError(false)
  }

  const fetRestaurant = (id) => {
    /*Fetches data from the API endpoint that retrieves all restaurant information for a given Table ID.*/
    fetch(`${API_URL}/allfunctionuser/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          let result = data['all_data']['resto']

          /*Sets the restaurant information based on the given result object.*/
          localStorage.setItem('login', true);
          localStorage.setItem('table_id', id);
          localStorage.setItem('resto_id', result.resto_id);
          const restaurantData = {
            ...result,
            resto_logo: `https://terbaiks.com/demos/fudly/uploads/user_logo/${result.resto_logo}`,
            resto_name: result.resto_name,
            hi_resto_name: result.hi_resto_name,
            resto_desc: result.resto_description,
            hi_resto_desc: result.hi_resto_description,
            resto_table: result.allot_table_name_number
          }
          setRestoInformation(restaurantData);
          dispatch(setRestInfo(restaurantData)
          )
          handleScanWebCam()
        }
      })
  }

  useEffect(() => {
    /*** Checks if the user is logged in and has a valid table ID stored in the local storage.
     * If both conditions are met, it redirects the user to the home page.**/
    if (localStorage.getItem('login') === 'true' && !!localStorage.getItem('table_id')) {
      history("/")
    }

    if (hasId && !!id && !isFetch) {
      setIsFetch(true)
      try {
        /*check allotted table.*/
        fetch(`${API_URL}/api/allfunctionuser/table_alloted/${id}`, { method: 'GET' })
          .then((response) => response.json())
          .then((data) => {
            if (data.status) {/*check table is successfully occupied.*/
              fetRestaurant(id)
            } else {
              setScanOpen(false)
              setScanning(false);
              const currentURL = window.location.href;
              const urlParts = currentURL.split('/');
              const lastPart = urlParts[urlParts.length - 1];
              let baseURL;
              if (lastPart !== 'login') {
                baseURL = urlParts.slice(0, -1).join('/');
              }
              window.history.pushState({}, document.title, baseURL);
              if (data.msg === 'Table Id Not Match!') {
                setIsErrorTable(true)
              }else if(data.msg?.includes('Subscription over')){
                setIsErrorSubscription(true)
              } else {
                localStorage.setItem('table_id',id)
                setIsError(true);
              }
            }
          })
      } catch (err) {
        console.log('code not correct in url')
      }
    }
  }, [hasId,id,history,isFetch])

  // Function to handle webcam errors
  const handleWebcamError = (error) => {
    console.log('Webcam error:', error);
    clearInterval(scanInterval);
    setScanOpen(false)
    setScanning(false);
    // Check the error message or type and set the appropriate error state
    if (error instanceof DOMException && error.name === 'PermissionDeniedError') {
      setIsCameraDenied(true);
    } else {
      setIsCameraError(true);
    }
  };

  var scanInterval;
  useEffect(() => {

    if (scanning) {
      scanInterval = setInterval(scanQrCode, 1000); // Adjust the interval as needed
    } else {
      clearInterval(scanInterval);
    }
    // return () => clearInterval(scanInterval);
  }, [webcamRef, scanning])

  const scanQrCode = () => {
    try {

      const video = webcamRef.current.video;
      var code
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        setErrorMessage(false)
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const { videoWidth, videoHeight } = video;

        canvas.width = videoWidth;
        canvas.height = videoHeight;

        context.drawImage(video, 0, 0, videoWidth, videoHeight);
        const imageData = context.getImageData(0, 0, videoWidth, videoHeight);
        code = QrCode(imageData.data, videoWidth, videoHeight);
      }

      if (code) {
        const tableId = code.data.substring(code.data.lastIndexOf('/') + 1);
        clearInterval(scanInterval);
        setScanOpen(false)
        setScanning(false);
        fetch(`${API_URL}/api/allfunctionuser/table_alloted/${tableId}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status) {
              fetRestaurant(tableId)
            } else {
              code = null
              clearInterval(scanInterval);
              setScanOpen(false)
              setScanning(false);
              console.log('call error message from 2 scan qr')
              if (data.msg === 'Table Id Not Match!') {
                setIsErrorTable(true)
              }else if(data.msg?.includes('Subscription over')){
                setIsErrorSubscription(true)
              }else {
                localStorage.setItem('table_id',tableId)
                setIsError(true);
              }
            }
          })
      }
    } catch (error) {
      console.error('Camera Error: ', error)
    }
  };


  const handleOpenScan = async () => {
    try {
      // await navigator.mediaDevices.getUserMedia({ video: true });
      setScanOpen(true);
      setScanning(true);
      setTimeout(() => {
        scanQrCode(); // Call scanQrCode immediately when scanning starts
      }, 100);
    } catch (error) {
      console.log('Camera access denied:', error);
      // Handle the error or show a message to the user
    }
  }

  const videoConstraints = {
    facingMode: { ideal: 'environment' },
  };

  return (
    <main className='container mx-auto max-w-xl overflow-hidden' style={{ maxHeight: '100dvh', minHeight: '100dvh' }}>
      {!loadingPage ?

        <div className="loginScreen w-full  flex flex-col items-center justify-between  relative" style={{ maxHeight: '100dvh', minHeight: '100dvh' }}>
          {/* <div className="topBar w-full min-h-12 p-4">
          </div> */}

          <div className='relative flex justify-center items-center ' style={{ height: 'calc(100dvh - 350px)' }}>
            <div className="w-[90%] rounded-xl max-w-sm" style={{ display: !scanOpen ? 'none' : 'block', maxHeight: 'calc(100dvh - 360px)' }}>
              {errorMessage ?
                errorMessage :
                <>
                  {scanOpen && (
                    <div className="relative" style={{ display: scanOpen ? "block" : "none" }}>
                      <Webcam
                        audio={false}
                        videoConstraints={videoConstraints}
                        ref={webcamRef}
                        onUserMediaError={handleWebcamError}
                        className='relative p-4 before:content-[""] z-10 before:absolute before:top-0 before:left-0 before:w-[102%] before:h-[102%] '
                        style={{ width: '100%', height: 'auto', borderRadius: '25px', aspectRatio: '1/1', objectFit: 'fill', maxHeight: 'calc(100dvh - 360px)' }}
                      />
                      <div className="absolute top-0 let-0 h-full w-full qrScanBlock"></div>
                    </div>
                  )}
                </>
              }
            </div>
          </div>

          <div className='relative flex flex-col items-center'>
            <div className="absolute -top-[15rem] flex justify-center">
              <img style={{ display: scanOpen ? 'none' : 'block' }} src={require('../assets/images/illustration/scanQr.png')} className='w-[450px] h-[400px]' alt="" />


            </div>
            <div className="loginBottom z-10 flex justify-center items-start w-[150%] rounded-t-full bg-white h-90">
              <div className="bottomMain flex flex-col h-full items-center justify-between mt-2 max-w-xl ps-[7.5rem] pe-[7.5rem] overflow-hidden">
                <div className="heading">
                  <div className='flex justify-center items-center'>
                    <img src={require('../assets/images/logo/fudly_logo.png')} className='w-20 h-20' alt="" /> <span class="text-[2rem] primary-font text-[#009B1F]">Fudly</span>
                  </div>

                  <h2 className='secondary-font text-3xl text-green-800 text-center'>Quick Menu Access</h2>
                  <p className='third-font text-slate-500 text-sm text-center mt-4 px-4'>Scan the QR code on your table to view the menu and place an order.</p>
                </div>
                <div className="actions mt-8 w-full  flex flex-col items-center gap-2">
                  <button onClick={handleOpenScan} className=' third-font p-2 rounded-full  w-full  max-w-sm mt-auto bg-[#017719] hover:bg-[#006314] text-white'>
                    Scan
                  </button>
                  <a href='https://fudly.in/web' target='_blank' rel='noreferrer' className='p-2 third-font rounded-full w-full max-w-sm border border-[#017719] text-[#017719] hover:bg-[#006314] hover:text-white flex items-center justify-center transition'>
                    What is fudly?
                  </a>

                  <footer class="text-center bottom-0 w-full text-xs text-gray-700 py-2 bg-gray-50 ">
                    Copyright © 2023 | Designed by <a href="https://www.terbaiks.com" className="whitespace-nowrap text-ellipsis underline" rel="noreferrer" target="_blank">Terbaiks Innovative Solutions LLP</a></footer>
                </div>
              </div>
            </div>

          </div>
        </div>
        :
        <div className="fram max-w-md relative mx-auto flex justify-center flex-col items-center h-screen mb-24">
          <img src={restoInformation.resto_logo} alt="Brand Logo" className="w-36 h-36 mb-6" />
          <h1 className="text-2xl font-bold mb-2 text-center p-1">Welcome to <span className='text-primary-500'>{restoInformation.resto_name}</span> </h1>
          <p className="text-gray-600 text-base text-center p-1">{restoInformation.resto_desc}</p>
          <div className="fixed bottom-0 ">
            <div aria-label="Loading..." className='flex justify-center mb-4' role="status">
              <svg class="h-8 w-8 animate-spin" viewBox="3 3 18 18">
                <path
                  class="fill-primary-200"
                  d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                <path
                  class="fill-primary-800"
                  d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
              </svg>
            </div>
            <p className="font-thin text-gray-400 text-sm mb-2">Preparing your unique dining experience... Please wait.</p>
          </div>
        </div>
      }
      <ScanErrorDialog isError={isError} handleClose={handleClose} />
      <ScanTableDialog isError={isErrorTable} handleClose={handleClose} />
      <SubscriptionOver isError={isErrorSubscription} handleClose={handleClose} />
      <CameraGenericError isShow={isCameraDenied} handleClose={handleClose} />
      <CameraError isShow={isCameraError} handleClose={handleClose} />
    </main>
  )
}

export default Login