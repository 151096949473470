import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import StatusChip from '../modules/custom-components/StatusChip';
import { setCancelOrderList } from '../store/reducers/orderDialog';
import { FiCheckCircle } from 'react-icons/fi'
import { RiTimer2Line } from 'react-icons/ri'
import { MdOutlineCancel } from 'react-icons/md'
import PendingOrder from '../components/order/PendingOrder';
import CompletedOrder from '../components/order/CompletedOrder';
import CanceledOrder from '../components/order/CanceledOrder';
import Comments from '../components/order/Comments';
import PaymentCalculation from '../components/order/PaymentCalculation';

const Order = () => {
    const { i18n, t } = useTranslation();
    const lang = i18n.language;
    const dispatch = useDispatch()
    const { orderData, orderCountCompleted, orderCountPending, orderComment, menu, restoInfo } = useSelector(state => ({
        orderData: state.orderData.orderData,
        orderComment: state.orderData.orderComment,
        orderCountCompleted: state.orderData.orderCountCompleted,
        orderCountPending: state.orderData.orderCountPending,
        menu: state.menu.menuItemWithId,
        restoInfo: state.restoInformation.restoInfo,
    }))
    const [productListWithId, setProductListWithId] = useState({})
    const [payAmount, setPayAmount] = useState(0)

    useEffect(() => {
        let flattenObj = {}
        Object.values(menu).forEach((val) => {
            flattenObj = { ...flattenObj, ...val }
        })
        setProductListWithId(flattenObj)
    }, [menu])

    const [isOpen, setIsOpen] = useState(true)
    const [isOpenHistory, setIsOpenHistory] = useState(true)
    const [isOpenCancel, setIsOpenCancel] = useState(true)
    const history = useNavigate()

    function handleRedirect() {
        history('/')
    }

    const orderListItems = useMemo(() => {
        if (orderData?.order_list && Object.keys(productListWithId).length > 0) {
            let pendingOrder = []
            let completedOrder = []
            let canceledOrder = []
            let totalAmt = 0
            orderData.order_list.forEach((order, index) => {
                if (order.ord_pro_status !== 'completed' && order.ord_pro_status !== 'cancel') {
                    // totalAmt += parseFloat(order.pro_price) * parseFloat(order.pro_qty)
                    pendingOrder.push(
                        <tr key={`orderItemTD${order.received_id}`} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap capitalize">
                                {productListWithId[order.product_id]?.[`${lang}_pro`]}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {order.pro_qty}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                <StatusChip t={t} status={order.ord_pro_status} />
                            </td>
                        </tr>
                    )
                } else if (order.ord_pro_status === 'cancel') {
                    canceledOrder.push(
                        <tr key={`orderItemTD${order.received_id}`} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap capitalize">
                                {productListWithId[order.product_id]?.[`${lang}_pro`]}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {order.pro_qty}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                <StatusChip t={t} status={order.ord_pro_status} />
                            </td>
                        </tr>
                    )
                    let cancelOrderStorage = JSON.parse(localStorage.getItem('cancelOrder'))
                    let cancelOrder = {}
                    cancelOrder[order.received_id] = false
                    if (cancelOrderStorage) {
                        if (!cancelOrderStorage.hasOwnProperty(order.received_id)) {
                            cancelOrderStorage[order.received_id] = false
                            localStorage.setItem('cancelOrder', JSON.stringify(cancelOrderStorage))
                            dispatch(setCancelOrderList(cancelOrderStorage))
                        }
                    } else {
                        localStorage.setItem('cancelOrder', JSON.stringify(cancelOrder))
                        dispatch(setCancelOrderList(cancelOrder))
                    }

                }
                else {
                    totalAmt += parseFloat(order.pro_price) * parseFloat(order.pro_qty)
                    completedOrder.push({ received_id: order.received_id, product_id: order.product_id, name: productListWithId[order.product_id]?.[`${lang}_pro`], pro_qty: order.pro_qty, ord_pro_status: order.ord_pro_status })
                    // completedOrder.push(
                    //     <tr key={`orderItemTD${order.received_id}`} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                    //         {/* <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td> */}
                    //         <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    //             {productListWithId[order.product_id]?.[`${lang}_pro`]}
                    //         </td>
                    //         <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    //             {order.pro_qty}
                    //         </td>
                    //         <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    //             <StatusChip t={t} status={order.ord_pro_status} />
                    //         </td>
                    //     </tr>
                    // )
                }
            });
            return { pendingOrder: pendingOrder, completedOrder: completedOrder, cancelOrder: canceledOrder, totalAmt: totalAmt, orderId: orderData?.order_list?.[0]?.order_id }
        }
        return null;
    }, [productListWithId, orderData.order_list, dispatch, lang, t]);

    return (
        <div className='p-3'>
            <section className="flex flex-col " id='orderPage'>
                {orderData?.order_list ?
                    <>
                        {orderListItems?.pendingOrder.length !== 0 &&
                            <div className='lg:w-full max-w-xl'>
                                <div className="relative flex md:px-4 px-0 py-4 md:py-0 md:pt-2   items-center">
                                    <span className="flex-shrink  mr-1 text-yellow-500 text-2xl flex items-end">
                                        {lang === 'eng' ?
                                            <>
                                                Pending <RiTimer2Line size={24} className='mb-[3px] ml-1' />rder
                                            </>
                                            :
                                            <>
                                                <RiTimer2Line size={28} className='mb-[3px] mr-1' /> <span className='text-[20px]'> पेंडिंग ऑर्डर</span>
                                            </>
                                        }
                                    </span>
                                    <div className="flex-grow border-t border-gray-400"></div>
                                </div>
                                {(orderData.order_status > 0 || orderData.order_status < 4) &&
                                    <PendingOrder orderData={orderData} orderListItems={orderListItems} orderCountPending={orderCountPending} setIsOpen={setIsOpen} isOpen={isOpen} />
                                }
                            </div>
                        }

                        {orderListItems?.pendingOrder.length !== 0 && orderComment?.length > 0 ?
                            <Comments orderComment={orderComment} key={'comment-1'} />
                            :
                            null
                        }

                        <div className={`max-w-xl lg:w-full ${orderListItems?.pendingOrder.length === 0 || orderListItems?.cancelOrder.length !== 0 ? 'mb-4' : 'mb-0'} lg:mb-0`}>

                            <div className="relative flex md:px-4 px-0 py-4 md:py-0 md:pt-2  items-center">
                                <span className="flex-shrink  mr-1 text-primary-400 text-2xl flex items-end">
                                    {lang === 'eng' ?
                                        <>
                                            C<FiCheckCircle size={16} className='mb-[5px]' />mpleted
                                        </>
                                        :
                                        <>
                                            <FiCheckCircle size={24} className='mb-[4px] mr-1' /> <span className='text-[20px]'> पूर्ण हुआ</span>
                                        </>
                                    }
                                </span>
                                <div className="flex-grow border-t border-gray-400"></div>
                            </div>
                            <CompletedOrder orderListItems={orderListItems} orderCountCompleted={orderCountCompleted} isOpenHistory={isOpenHistory} isOpen={isOpen} setIsOpenHistory={setIsOpenHistory} />
                        </div>

                        {orderListItems?.completedOrder.length > 0 ?
                            <PaymentCalculation setPayAmount={setPayAmount} classList={orderListItems?.pendingOrder.length === 0 || orderListItems?.cancelOrder.length !== 0 ? 'mb-8' : 'mb-36'} itemTotal={orderListItems?.totalAmt} data={restoInfo} />
                            :
                            <></>
                        }

                        {(orderListItems?.pendingOrder.length === 0 && orderListItems?.completedOrder.length !== 0) &&
                            <div id="alert-additional-content-3" class="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
                                <div class="flex items-center">
                                    <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                    </svg>
                                    <span class="sr-only">Info</span>
                                    <h3 class="text-lg font-medium">{t('order.completeAlertTitle')}</h3>
                                </div>
                                <div class="mt-2 mb-4 text-sm">
                                    {t('order.completeAlertMessage')}
                                </div>
                                {
                                    (restoInfo?.upi_status === 'active' && restoInfo?.marchant_upi_id?.length > 0) ?
                                        <div class="flex justify-end">
                                            <button class="bg-primary-600 text-white px-4 py-2 text-sm third-font rounded-md hover:bg-primary-700 transition duration-300">
                                                <a href={`upi://pay?pa=${restoInfo?.marchant_upi_id}&pn=${encodeURIComponent(restoInfo?.resto_name)}&am=${payAmount}&tr=${encodeURIComponent(orderListItems?.orderId || 0)}&tn=${encodeURIComponent('Order payment')}`}>Pay Online: {payAmount}</a>
                                            </button>
                                        </div>
                                        :
                                        null

                                }
                            </div>
                        }

                        {orderListItems?.pendingOrder.length === 0 && orderComment?.length > 0 ?
                            <Comments orderComment={orderComment} key={'comment-2'} className='mb-8' />
                            :
                            null
                        }

                        {orderListItems?.cancelOrder.length > 0 &&
                            <div className={`max-w-xl lg:w-full mb-8 lg:mb-0`}>

                                <div className="relative flex md:px-4 px-0 py-4 md:py-0 md:pt-2  items-center">
                                    <span className="flex-shrink  mr-1 text-gray-600 text-2xl flex items-end">
                                        {lang === 'eng' ?
                                            <>
                                                <MdOutlineCancel size={24} className='mb-[4px] mr-1' /> Canceled Order
                                            </>
                                            :
                                            <>
                                                <MdOutlineCancel size={24} className='mb-[4px] mr-1' /> <span className='text-[20px]'> रद्द किया गया ऑर्डर</span>
                                            </>
                                        }
                                    </span>
                                    <div className="flex-grow border-t border-gray-400"></div>
                                </div>
                                <CanceledOrder orderListItems={orderListItems?.cancelOrder} isOpenCancel={isOpenCancel} setIsOpenCancel={setIsOpenCancel} />
                            </div>
                        }

                    </> :
                    <div className="w-full flex justify-center items-center">
                        <div className="foodOrderCard border rounded-[15px] relative border-gray-400 border-opacity-50 w-sm shadow-md bg-secondary-50 ">
                            <div class="flex flex-col items-center justify-center p-md-20 p-5">
                                <img src={require('../assets/images/illustration/undraw_Chef_cu0r.png')} className='w-64 mb-4' alt="" />
                                {/* <img src={require('./assets/images/cartAnimation.gif')} className='w-24  mb-4' alt='empty cart'/> */}
                                <h2 class="text-2xl font-bold text-primary-500 mb-2">{t('order.noOrderHead')}<span className='text-lg'>😔</span></h2>
                                <p class="text-gray-500 mb-4 text-center ">
                                    {t('order.noOrderMessage')}
                                </p>
                                <button onClick={() => handleRedirect()} class="inline-block px-6 py-2 text-md font-semibold text-white bg-primary-500 rounded-full shadow-lg hover:bg-primary-600 transition-colors duration-300">
                                    {t('order.noOrderBtn')} 🍽️
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </section>

        </div>
    )
}

export default Order