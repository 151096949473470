
/*The base URL of the API endpoint.*/
const API_URL = process.env.REACT_APP_API_URL

/**
 * Fetches data from a specified URL using the GET method.
 * url - The URL to fetch the data from (without base url).
 * name - The name of the data for show error.
 * A promise that resolves to an object containing the fetched data if successful,
 * or rejects with an error message if unsuccessful.
 */
const fetchData = (url, name) => {
  return new Promise(async (res, rej) => {
    fetch(`${API_URL}/${url}`, { method: 'GET' })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch ${name} data`);
        }
        return response.json();
      })
      .then((response) => {
        if (response.status === true) {
          res({ status: true, data: response });
        } else {
          rej({ status: false, message: response.msg });
        }
      })
      .catch((error) => {
        rej({ message: error.message, status: false });
      });
  });
};

export default fetchData;