import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { HiExclamation } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import CustomDialog from '../../modules/dialog/CustomDialog';
import { t } from 'i18next';

const API_URL = process.env.REACT_APP_API_URL;
const LogoutDialog = ({ isShow, handleClose }) => {

    const isOrderRunning = !!localStorage.getItem('order_id');
    const tableId = localStorage.getItem('table_id');
    const handleLogout = () => {
        // setPageLoader(true)
        try {
            fetch(`${API_URL}/api/api/logout_withtable/${tableId}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status) {
                        const localFcm = localStorage.getItem("baseNotification");
                        localStorage.clear()
                        localStorage.setItem("baseNotification", localFcm);
                        setTimeout(() => {
                            // setPageLoader(false)
                        }, 2000)
                        window.location.href = '/login'
                    } else {
                        // setPageLoader(false)
                        console.log('some error in logout', data)
                    }
                })
        } catch (error) {
            // setPageLoader(false)
            console.log('some error in logout', error)
        }
    };

    return (
        <>
            {isOrderRunning ?
                <CustomDialog
                    isShow={isShow}
                    handleClose={handleClose}
                    icon={<HiExclamation className="h-6 w-6 text-red-600" aria-hidden="true" />}
                    title={t('dialog.logOut.notLogOutTitle')}
                    message={t('dialog.logOut.notLogOutMessage')}
                    btnText={t('dialog.logOut.notLogOutBtn')}
                    btnAction={handleClose}
                />
                :
                <CustomDialog
                    isShow={isShow}
                    handleClose={handleClose}
                    icon={<HiExclamation className="h-6 w-6 text-red-600" aria-hidden="true" />}
                    title={t('dialog.logOut.logOutTitle')}
                    message={t('dialog.logOut.logOutMessage')}
                    btnText={t('dialog.logOut.logOutBtn')}
                    btnAction={handleLogout}
                    btnClose={true}
                />
            }
        </>
    );
};

export default LogoutDialog;
