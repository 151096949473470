import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setOrderData} from '../store/reducers/orderData';
import { setOrderUpdate} from '../store/reducers/orderDialog';
import { setCompleteOrderCanceled, setCancelOrderList } from '../store/reducers/orderDialog';
import { fetchData } from '../api';

function useFetchOrderData(page='unknown') {
    const dispatch = useDispatch();
    const history = useNavigate()
    
    const fetchOrderData = async (orderId,updateDate) => {
        if (orderId) {
            try {
                const data = await fetchData(`order/${orderId}`)
                var isSomeOderCancel=false
                if (data.status) {
                    const orderData = data.data['order_list']
                    const orderStatus = orderData.order_status;
                    let statusInNumber;
                    if (orderStatus === 'cancel') {
                        statusInNumber = 0;
                    } else if (orderStatus === 'pending') {
                        statusInNumber = 1;
                    } else if (orderStatus === 'inprogress') {
                        statusInNumber = 2;
                    } else if (orderStatus === 'dispatch') {
                        statusInNumber = 3;
                    } else if (orderStatus === 'completed') {
                        statusInNumber = 4;
                    } else if (orderStatus === 'payment') {
                        statusInNumber = 5;
                    }

                    dispatch(setOrderData({ order_status: statusInNumber, order_list: data.data['order_list']['order_product_list'],order_comment:data.data['order_list']['order_comment_prefreance'] }));
                    data.data['order_list']['order_product_list'].forEach((order) => {
                        if (order.ord_pro_status === 'cancel') {
                            let cancelOrderStorage = JSON.parse(localStorage.getItem('cancelOrder'))
                            let cancelOrder = {}
                            cancelOrder[order.received_id] = false
                            if (cancelOrderStorage) {
                                if (!cancelOrderStorage.hasOwnProperty(order.received_id)) {
                                    cancelOrderStorage[order.received_id] = false
                                    localStorage.setItem('cancelOrder', JSON.stringify(cancelOrderStorage))
                                    dispatch(setCancelOrderList(cancelOrderStorage))
                                    isSomeOderCancel=true
                                }
                            } else {
                                localStorage.setItem('cancelOrder', JSON.stringify(cancelOrder))
                                dispatch(setCancelOrderList(cancelOrder))
                            }
    
                        }
                    })
                    if (statusInNumber === 5) {
                        let invoiceId = btoa(`${localStorage.getItem('table_id')}-${localStorage.getItem('order_id')}`)
                        history(`/orderDone/${invoiceId}`)
                    } else if (statusInNumber === 0) {
                        dispatch(setCompleteOrderCanceled(true))
                    } else {
                        if(!updateDate && page !== 'unknown' && !isSomeOderCancel){
                            dispatch(setOrderUpdate(true))
                        }
                    }
                }
            } catch (err) {
    
            }
        }
    }
    return fetchOrderData;
}



export default useFetchOrderData;