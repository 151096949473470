import { useState, useEffect } from 'react'
import { Transition } from '@headlessui/react';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { BiSolidCalculator, BiSolidMessageSquareDetail } from "react-icons/bi";

const PaymentCalculation = ({ itemTotal, data, classList, setPayAmount }) => {
    const [calculation, setCalculation] = useState({})
    useEffect(() => {
        const serviceCharge = itemTotal * (parseFloat(data?.inner_service_charge || 0) / 100)
        const afterAdjustment = data?.resto_service_charge === '1' ? itemTotal + serviceCharge : itemTotal
        if (data?.resto_gst_type === 'exclusive') {
            const gst = afterAdjustment * (parseFloat(data?.gst_percent_value || 0) / 100)
            const total = afterAdjustment + gst
            setCalculation({
                itemTotal,
                gst,
                total,
                serviceCharge
            })
            setPayAmount((total || 0).toFixed(2))
        } else {
            setCalculation({
                itemTotal,
                total: afterAdjustment,
                serviceCharge
            })
            setPayAmount((afterAdjustment || 0).toFixed(2))
        }
    }, [])
    return (
        <div className={`foodOrderCardContainer sm:p-4 pt-4 transition-all ${classList}`}>
            <div className="foodOrderCard border rounded-[15px] transition-all relative border-gray-400 border-opacity-50 shadow-md bg-secondary-50 ">
                <div className="orderCardHeader px-2 border-b-2 overflow-hidden text-ellipsis">
                    <div className="max-w-xl flex justify-between items-center mx-auto my-3">
                        <h5 className='text-lg text-slate-700 flex items-center gap-2 secondary-font'>
                            <BiSolidCalculator size={25} />
                            <span>Payment Calculation</span>
                        </h5>
                        <div className=" flex p-1 justify-center items-center rounded-full third-font text-primary-500 text-sm">
                            Pay Amount: Rs.{calculation?.total}
                        </div>
                    </div>
                </div>
                <Transition
                    show={true}
                    enter="transform transition-all duration-500"
                    enterFrom="max-h-0 opacity-0"
                    enterTo={`max-h-screen opacity-100`}
                    leave="transform transition-all duration-500"
                    leaveFrom={`max-h-screen opacity-100`}
                    leaveTo="max-h-0 opacity-0"
                >
                    <div className="orderCardBody  bg-white rounded-b-[15px]">
                        <div className="flex flex-col">
                            <div className="sm:mx-0.5 lg:mx-0.5">
                                <div className="inline-block min-w-full overflow-y-auto max-h-[97vh] style-scroll">
                                    <div className="overflow-visible flex flex-col gap-2">
                                        <div class="border border-t-0 rounded-lg rounded-t-none overflow-hidden dark:border-gray-700">
                                            <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                                <thead class="bg-gray-50 dark:bg-gray-700">
                                                    <tr>
                                                        <th scope="col" class="px-6 py-3 text-start text-sm font-medium text-gray-500 uppercase dark:text-gray-400">Itme Total
                                                            {data?.resto_gst_type !== 'exclusive' ?
                                                                <>
                                                                    <br /> (GST Included)
                                                                </>
                                                                :
                                                                null
                                                            }
                                                        </th>
                                                        <th scope="col" class="px-6 py-3 text-end text-sm font-medium text-gray-500 uppercase dark:text-gray-400">{(calculation?.itemTotal || 0.00).toFixed(2)}</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                                                    {calculation?.gst > 0 ?
                                                        <>
                                                            <tr>
                                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">CGST ({(parseFloat(data?.gst_percent_value || 0)).toFixed(1)}%)</td>
                                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-end text-gray-800 dark:text-gray-200 ">{(calculation?.gst / 2 || 0).toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">SGST ({(parseFloat(data?.gst_percent_value || 0)).toFixed(1)}%)</td>
                                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-end text-gray-800 dark:text-gray-200 ">{(calculation?.gst / 2 || 0).toFixed(2)}</td>
                                                            </tr>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                    }
                                                    {
                                                        data?.resto_service_charge === '1' ?
                                                            <tr>
                                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Service Charge ({(parseFloat(data?.inner_service_charge || 0)).toFixed(1)}%)</td>
                                                                <td class="px-6 py-4 whitespace-nowrap text-sm text-end text-gray-800 dark:text-gray-200 ">{(calculation?.serviceCharge || 0).toFixed(2)}</td>
                                                            </tr>
                                                            :
                                                            null

                                                    }
                                                    <tr>
                                                        <td class="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-500 dark:text-gray-200">Grand Total</td>
                                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-end font-bold text-gray-500 dark:text-gray-200">{(calculation?.total || 0).toFixed(2)}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    )
}

export default PaymentCalculation