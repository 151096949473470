import React, { useEffect, useMemo, useState } from 'react'
import TopTabBar from '../modules/nav-bar/TopTabBar';
import { useSelector, useDispatch } from 'react-redux';
import ProductTab from '../components/home/ProductTab';
import { setViewMode } from '../store/reducers/viewMode';
import { fetchData } from '../api';
import { setMenuItem } from '../store/reducers/menu';
import { setCategoryItem } from '../store/reducers/category';
import { setRestInfo } from '../store/reducers/restaurant';
import { Transition } from '@headlessui/react';
import smallLogo from '../assets/images/logo/fudly_logo_without_circle.png'

const API_URL = process.env.REACT_APP_API_URL;

const Home = () => {
    const dispatch = useDispatch()
    const [isAppLoading, setIsAppLoading] = useState(false)

    useEffect(() => {
        const fetchMenu = async () => {
            const tableId = localStorage.getItem('table_id');
            if (tableId) {
                try {
                    setIsAppLoading(true);
                    const data = await fetchData(`allfunctionuser/${tableId}`, 'data');
                    if (data.status) {
                        const restInfo = data.data['all_data']['resto'];
                        const category = data.data['all_data']['category'];
                        const product = data.data['all_data']['products'];

                        const filteredCategory = { ...category, 'pureVeg': category['veg'] || [], 'nonVeg': category['nonveg'] || [], 'beverage': category['brg'] || [] };
                        var filteredCategoryId = {};
                        Object.keys(filteredCategory).forEach((key) => {
                            filteredCategoryId[key] = filteredCategory[key].reduce((accumulator, item) => {
                                accumulator[item.category_id] = item;
                                return accumulator;
                            }, {});
                        });

                        var filteredProduct = { 'pureVeg': product['veg'], 'nonVeg': product['nonveg'] || [], 'beverage': product['brg'] || [], 'bar': product['bar'] || [] };
                        Object.keys(filteredProduct).forEach((p) => {
                            filteredProduct[p].forEach((item, index) => {
                                if (filteredCategoryId[p]?.[item['category_id']]) {
                                    filteredProduct[p][index]['eng_category_name'] = filteredCategoryId[p][item['category_id']]['eng_cat'];
                                    filteredProduct[p][index]['hin_category_name'] = filteredCategoryId[p][item['category_id']]['hin_cat'];
                                } else {
                                    filteredProduct[p][index]['eng_category_name'] = '';
                                    filteredProduct[p][index]['hin_category_name'] = '';
                                }
                            })
                        })
                        // filteredProduct['bar'].push(...filteredProduct['beverage'])

                        dispatch(setMenuItem(filteredProduct));
                        dispatch(setCategoryItem(filteredCategoryId));
                        dispatch(setRestInfo({
                            ...restInfo,
                            resto_logo: `${API_URL}/uploads/user_logo/${restInfo.resto_logo}`,
                            resto_name: restInfo.resto_name,
                            hi_resto_name: restInfo.hi_resto_name,
                            resto_desc: restInfo.resto_description,
                            hi_resto_desc: restInfo.hi_resto_description,
                            resto_table: restInfo.allot_table_name_number
                        }));
                    };
                    setIsAppLoading(false);

                } catch (error) {
                    setIsAppLoading(false);
                }
            };
            setIsAppLoading(false);
        };

        dispatch(setViewMode('guest'));
        fetchMenu();
    }, [dispatch]);

    if (isAppLoading) {
        return (
            <Transition
                key={'cartFullViewOverlay'}
                // as={React.Fragment}
                show={isAppLoading}
                enter="ease-out duration-300 z-40"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div class="fixed z-30 w-full h-full left-0 top-0 inset-0 bg-gray-500 bg-opacity-75 transition-opacity max-w-xl mx-auto"></div>
                <div class="flex-col gap-4 w-full flex items-center justify-center absolute top-[50%] -translate-y-[80px] left-[0%] z-40">
                    <div class="w-28 h-28 border-0 text-primary-500 text-4xl  border-gray-300 flex items-center justify-center border-t-primary-500 rounded-full">
                        <img src={smallLogo} height="100%" width="100%" class="" alt="" srcset="" />
                    </div>
                    <span className='secondary-font text-xl text-white'>Getting ready
                        <span className='animate-ping'>.</span>
                        <span className='animate-ping'>.</span>
                        <span className='animate-ping'>.</span>
                    </span>
                </div>
            </Transition>
        )
    }

    return (
        <div className=''>
            <TopTabBar
                pureVeg={<ProductTab key={'productTab-1'} type={'pureVeg'} />}
                nonVeg={<ProductTab key={'productTab-2'} type={'nonVeg'} />}
                barDrink={<ProductTab key={'productTab-3'} type={'bar'} />}
                beverages={<ProductTab key={'productTab-4'} type={'beverage'} />} />
        </div>
    )
}


export default Home