import React, { useEffect, useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import { CgShoppingBag } from 'react-icons/cg'
import { BsCaretRightFill } from 'react-icons/bs'
import { TbMinus, TbPlus } from 'react-icons/tb';
import { increaseCartItemQuantity, decreaseCartItemQuantity, addToCart } from '../../../store/reducers/menu'
import { showProductPreview } from '../../../store/reducers/productPreview'
import { useDispatch, useSelector } from 'react-redux'
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

const MEDIA_URL = process.env.REACT_APP_MEDIA_URL

const ProductPreviewModal = () => {
    const { i18n, t } = useTranslation();
    const lang = i18n.language;
    const { isShow, productId, type, mode } = useSelector(state => ({
        isShow: state.productPreview.isPreviewShow,
        productId: state.productPreview.productPreviewId,
        type: state.productPreview.productType,
        mode: state.viewMode.mode
    }));

    return (
        <PreView isOpen={isShow} productId={productId} type={type} t={t} lang={lang} mode={mode} />
    )
}

const PreView = ({ productId, isOpen, type, lang, t, mode }) => {
    const { product } = useSelector(state => ({
        product: state.menu.menuItemWithId,
    }));
    const data = product?.[type]?.[productId]

    const inCart = data?.in_cart || false

    const dispatch = useDispatch()

    const handleAddTOCart = () => {
        dispatch(addToCart(data.product_id))
    }

    const handleDecrement = () => {
        dispatch(decreaseCartItemQuantity(data.product_id))
    }

    const handleIncrement = () => {
        dispatch(increaseCartItemQuantity(data.product_id))
    }

    const handleClose = () => {
        dispatch(showProductPreview({ show: false, id: null }))
        // Remove the disable-scroll class from the root div (body)
        document.body.classList.remove('disable-scroll');
    }
    return (
        <>
            <Transition
                key={'cartFullViewOverlay'}
                as={React.Fragment}
                show={isOpen}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div class="fixed z-30 w-full h-full  top-0 bg-gray-500 bg-opacity-75 transition-opacity mx-auto max-w-xl " onClick={handleClose}></div>
            </Transition>

            <Transition
                key={'cartFullView'}
                as={React.Fragment}
                show={isOpen}
                enter="transition-transform duration-300 ease-in-out"
                enterFrom="translate-y-full"
                enterTo="-translate-y-full"
                leave="transition-transform duration-300 ease-in-out"
                leaveFrom="-translate-y-full"
                leaveTo="translate-y-full"
            >
                <div style={{ maxHeight: 'calc(100vh - 120px)',minHeight: 'calc(100vh - 250px)' }} className=" top-full transition-transform duration-400 z-30  fixed rounded-t-2xl ease-material-in bg-white dark:bg-black -translate-y-full w-full max-w-xl mx-auto ">
                    <div className="w-full z-30 relative" >
                        {/* <div className="absolute w-full h-full left-0 top-0 rounded-t-2xl" /> */}
                        <div className="flex relative justify-between items-center w-full overflow-hidden rounded-t-2xl h-[200px]">
                            <button onClick={handleClose} className="absolute top-2 right-2  p-2 rounded-full bg-slate-700/60 flex justify-center items-center text-xl text-white font-bold "><RxCross1 size={20} /></button>
                            <img src={`${MEDIA_URL}/${data?.img1}`} className='object-cover h-full w-full' alt="" />
                        </div>
                    </div>
                    <div className="text-sm z-10 relative overflow-y-auto bg-white" style={{ maxHeight: 'calc(42vh)' }}>
                        <div className="flex justify-between bg-white w-full px-3 pt-4">
                            <div className=' '>
                                <h2 className="text-xl font-semibold text-slate-900 secondary-font leading-5 capitalize">
                                    {data?.[`${lang}_pro`]}
                                </h2>
                                {/* whitespace-nowrap */}
                                <p className=" text-sm text-gray-500 flex items-center mt-1   third-font">
                                    {data?.[`${lang}_category_name`]}
                                    <span className=" block font-bold mx-1 text-sm" aria-hidden="true">&middot;</span>
                                    &#8377;{parseInt(data?.product_price)} {t('product.countMember')}
                                </p>
                                <p className="text-slate-800 font-semibold third-font text-sm mt-1">&#8377;{data?.product_price}</p>
                            </div>
                            <div className='flex flex-col '>
                                {!inCart ?
                                    <>
                                        {mode === 'guest' ?
                                            <></>
                                            :
                                            <button onClick={handleAddTOCart} className="w-[6rem] py-1 px-2 text-lg text-primary-500 bg-[#f8fcf9] border border-primary-500 font-semibold secondary-font  rounded-lg flex justify-center items-center gap-1">
                                                {t('product.cartBtn')} +
                                            </button>
                                        }
                                    </>
                                    :
                                    <>
                                        <div className="w-[6rem] py-1 px-2 text-lg text-primary-500 bg-[#f8fcf9] border border-primary-500 font-semibold secondary-font  rounded-lg flex justify-between items-center gap-1">
                                            <button className="decrease" onClick={handleDecrement}>
                                                <TbMinus />
                                            </button>
                                            <span className="qtyCount font-semibold text-primary-600 ">
                                                {data?.item_qty}
                                            </span>
                                            <button className="increase" onClick={handleIncrement}>
                                                <TbPlus />
                                            </button>
                                        </div>

                                        <p className="text-slate-800 font-semibold text-sm text-right mt-1 third-font ">&#8377;{parseInt(data.product_price) * data.item_qty}</p>
                                    </>
                                }
                            </div>
                        </div>
                        <div>
                            <p className='px-3 text-base text-slate-500 third-font py-2 pb-6'>
                                {data?.[`${lang}_desc_pro`]}
                            </p>
                        </div>

                    </div>
                </div>
            </Transition>
        </>
    )
}

export default ProductPreviewModal