import './App.css';
import './firebase'
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './modules/layouts/Main';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import { setAuthentication } from './store/reducers/auth';
import { setMenuItem } from './store/reducers/menu';
import { useDispatch } from 'react-redux';
import { getAuthStatus } from './utils/oidc-providers';
import { fetchData } from './api'
import smallLogo from './assets/images/logo/fudly_logo_without_circle.png'

// ** Pages
import Login from './pages/Login'
import Home from './pages/Home'
import GuestHome from './pages/GuestHome'
import Contact from './pages/Contact';
import Order from './pages/Order';
import Blank from './modules/layouts/Blank';
import { showInfoLogOut } from './store/reducers/logOut';
import { setRestInfo } from './store/reducers/restaurant';
import { setCategoryItem } from './store/reducers/category';
import OrderDone from './pages/OrderDone';
import { Transition } from '@headlessui/react';
import GuestRoute from './routes/GuestRoute';
import Guest from './modules/layouts/Guest';

const API_URL = process.env.REACT_APP_API_URL;


function App() {
  const dispatch = useDispatch();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
  });
  useEffect(() => {
    checkSession();
    // setInterval(checkingLoginStatus, 5000)
  }, []);

  const checkSession = async () => {
    try {
      let responses =
        await Promise.all([
          getAuthStatus(),
        ]);

      responses = responses.filter((r) => Boolean(r));

      if (responses) {
        const tableId = localStorage.getItem('table_id')
        dispatch(setAuthentication(responses[0]));
        try {
          const data = await fetchData(`allfunctionuser/${tableId}`, 'data')
          if (data.status) {
            const restInfo = data.data['all_data']['resto']
            const category = data.data['all_data']['category']
            const product = data.data['all_data']['products']

            const filteredCategory = { ...category, 'pureVeg': category['veg'] || [], 'nonVeg': category['nonveg'] || [], 'beverage': category['brg'] || [] }
            var filteredCategoryId = {}
            Object.keys(filteredCategory).forEach((key) => {
              filteredCategoryId[key] = filteredCategory[key].reduce((accumulator, item) => {
                accumulator[item.category_id] = item;
                return accumulator;
              }, {});
            })

            var filteredProduct = { 'pureVeg': product['veg'], 'nonVeg': product['nonveg'] || [], 'beverage': product['brg'] || [], 'bar': product['bar'] || [] }
            Object.keys(filteredProduct).forEach((p) => {
              filteredProduct[p].forEach((item, index) => {
                if (filteredCategoryId[p]?.[item['category_id']]) {
                  filteredProduct[p][index]['eng_category_name'] = filteredCategoryId[p][item['category_id']]['eng_cat']
                  filteredProduct[p][index]['hin_category_name'] = filteredCategoryId[p][item['category_id']]['hin_cat']
                } else {
                  filteredProduct[p][index]['eng_category_name'] = ''
                  filteredProduct[p][index]['hin_category_name'] = ''
                }
              })
            })
            // filteredProduct['bar'].push(...filteredProduct['beverage'])

            dispatch(setMenuItem(filteredProduct))
            dispatch(setCategoryItem(filteredCategoryId))
            dispatch(setRestInfo({
              ...restInfo,
              resto_logo: `${API_URL}/uploads/user_logo/${restInfo.resto_logo}`,
              resto_name: restInfo.resto_name,
              hi_resto_name: restInfo.hi_resto_name,
              resto_desc: restInfo.resto_description,
              hi_resto_desc: restInfo.hi_resto_description,
              resto_table: restInfo.allot_table_name_number,
            }))
          }
        setIsAppLoading(false);
        console.log('apploading', false);

        } catch (error) {
        }
      }else {
        dispatch(setAuthentication(undefined));
      }
      setIsAppLoading(false);

    } catch (error) {
      console.log('error', error);
    }
    setIsAppLoading(false);

  };

  // const checkingLoginStatus = async () => {
  //   const tableId = localStorage.getItem('table_id');
  //   const pageName = (window.location.pathname).split('/')[1]
  //   const noOrderId = !localStorage.getItem('order_id') || localStorage.getItem('order_id') === 'null'
  //   if (pageName !== 'orderDone' && pageName !== 'viewMenu/guest' && noOrderId && !!tableId) {
  //     try {
  //       const logStatus = await fetchData(`api/allfunctionuser/table_alloted_status/${tableId}`, 'logStatus')
  //       if (logStatus.status) {
  //         dispatch(showInfoLogOut(true))
  //       }
  //       console.log('This is login data ', logStatus)
  //     } catch (error) {
  //       // console.log('error in fetch lgin information', error)
  //     }
  //   }
  // }

  return (
    <>
      {
        isAppLoading ?
          (
            <Transition
                    key={'cartFullViewOverlay'}
                    // as={React.Fragment}
                    show={isAppLoading}
                    enter="ease-out duration-300 z-40"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div class="fixed z-30 w-full h-full left-0 top-0 inset-0 bg-gray-500 bg-opacity-75 transition-opacity max-w-xl mx-auto"></div>
                    <div class="flex-col gap-4 w-full flex items-center justify-center absolute top-[50%] -translate-y-[80px] left-[0%] z-40">
                        <div class="w-28 h-28 border-0 text-primary-500 text-4xl  border-gray-300 flex items-center justify-center border-t-primary-500 rounded-full">
                            <img src={smallLogo} height="100%" width="100%" class="" alt="" srcset="" />
                        </div>
                            <span className='secondary-font text-xl text-white'>Getting ready 
                            <span className='animate-ping'>.</span> 
                            <span className='animate-ping'>.</span> 
                            <span className='animate-ping'>.</span> 
                            </span>
                    </div>
                </Transition>
          ) : (
            // basename="/demos/fudly-user"
            // "homepage": "/demos/fudly-user",

            <BrowserRouter >
              <Routes>
                <Route path="/orderDone/:invoiceId" element={<OrderDone checkSession={checkSession} />} />
                <Route path="/login" element={<PublicRoute />}>
                  <Route path="/login" element={<Login checkSession={checkSession} hasId={false} />} />
                  <Route path="/login:id" element={<Login checkSession={checkSession} hasId={true} />} />
                </Route>

                <Route path="/viewMenu" element={<GuestRoute />}>
                  <Route path="/viewMenu/guest" element={<Guest />}>
                    <Route path="/viewMenu/guest" element={<GuestHome />} />
                  </Route>
                </Route>

                <Route path="/" element={<PrivateRoute />}>
                  <Route path="/contactUs" element={<Blank />}>
                    <Route path="/contactUs" element={<Contact />} />
                  </Route>
                  <Route path="/order" element={<Blank />}>
                    <Route path="/order" element={<Order />} />
                  </Route>
                  <Route path="/" element={<Main />}>
                    <Route path="/" element={<Home />} />
                  </Route>
                </Route>

              </Routes>
            </BrowserRouter>
          )
      }
    </>
  );
}

export default App;
