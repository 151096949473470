import React from 'react';
import { HiExclamation } from 'react-icons/hi';
import CustomDialog from '../../modules/dialog/CustomDialog';
import { t } from 'i18next';

const ScanTableDialog = ({ isError, handleClose }) => {
    return (
        <CustomDialog 
            isShow={isError} 
            handleClose={handleClose}
            icon={<HiExclamation className="h-6 w-6 text-red-600" aria-hidden="true" />}
            title={t('dialog.login.scanTableTitle')}
            message={t('dialog.login.scanTableMessage')}
            btnText={t('dialog.login.scanTableBtn')}
            btnAction={handleClose}
        />
    );
};

export default ScanTableDialog;
