import React, { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import TopNavbar from '../nav-bar/TopNavbar';
import BottomTab from '../bottom-tab/BottomTab';
import LogoutByAdmin from '../../dialogs/log-out/LogOutByAdmin';
import LanguageOptions from '../../components/language/LanguageOptions';
import useFetchOrderData from '../../hooks/useFetchOrderData';
import { fetchData } from '../../api';
import OrderStatusUpdate from '../../dialogs/order/OrderStatusUpdate';
import CanceledOrderItem from '../../dialogs/order/CanceledOrderItem';
import { showInfoLogOut } from '../../store/reducers/logOut';
import { useDispatch } from 'react-redux';

const Blank = () => {
  // const authentication = useSelector(state => state.auth.authentication);
  const dispatch= useDispatch()
  const authentication = true;
  const [isAppLoaded, setIsAppLoaded] = useState(true);
  const fetchOrderData = useFetchOrderData('blank');


  useEffect(() => {
    setTimeout(() => {
      setIsAppLoaded(Boolean(authentication));
    }, 3000)
  }, [authentication]);

  const checkingLoginStatus = async () => {
    const tableId = localStorage.getItem('table_id');
    const pageName = (window.location.pathname).split('/')[1]
    if (pageName !== 'orderDone' && pageName !== 'viewMenu/guest' && !!tableId) {
      try {
        const logStatus = await fetchData(`api/allfunctionuser/table_alloted_status/${tableId}`, 'logStatus')
        if (logStatus.status) {
          dispatch(showInfoLogOut(true))
        }
        console.log('This is login data ', logStatus)
      } catch (error) {
        // console.log('error in fetch lgin information', error)
      }
    }
  }

  useEffect(() => {
    fetchOrderData(localStorage.getItem('order_id'),true)

    const fetchDataOrder = async () => {
      if (!!localStorage.getItem('order_id') && localStorage.getItem('order_id') !== 'null') {
        try {
          const data = await fetchData(`api/order/newproodrusr/${localStorage.getItem('order_id')}`, 'order Data');

          if (data.status) {
            const updatedDate = data.data.updated_date;
            // Get the last updated date from local storage
            const storedLastUpdated = localStorage.getItem('lastUpdated');
            // Store the new last updated date in local storage
            localStorage.setItem('lastUpdated', updatedDate);

            // Compare the stored last updated date with the new one
            if (storedLastUpdated !== updatedDate && storedLastUpdated !== 'not') {
              fetchOrderData(localStorage.getItem('order_id'),false)
            }

          }
        } catch (error) {
          console.error('Error fetching order data:', error);
        }
        checkingLoginStatus()
      }else{
        checkingLoginStatus()
      }
    };
    const intervalId = setInterval(() => {
      // Fetch order data at the specified interval
      fetchDataOrder()
    }, 5000);

    return () => {
      // Clear the interval when the component unmounts to avoid memory leaks
      clearInterval(intervalId);
    };
  }, [])

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return (
        <>Loading </>
      );
    }
    return (
      <>
        <div className="content-wrapper layout-wrapper layout-content-navbar min-w-screen min-h-screen max-w-xl mx-auto ">
          {/* <div className="pt-3 layout-container" /> */}
          <TopNavbar />
          <OrderStatusUpdate />
          <section className="content ">
            <Outlet />
          </section>
          <BottomTab />
          <LanguageOptions />
          <LogoutByAdmin />
          <CanceledOrderItem />
          {/* <Page>
          </Page> */}
        </div>
      </>
    );
  }, [isAppLoaded]);

  return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Blank;
