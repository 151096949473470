import React from 'react'
import CustomDialog from '../../modules/dialog/CustomDialog'
import { HiCheck } from 'react-icons/hi';
import { t } from 'i18next';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setOrderSuccess } from '../../store/reducers/orderDialog';

const OrderSuccess = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const {isOrderSuccess} = useSelector(state => ({
        isOrderSuccess: state.orderDialog.isOrderSuccess
    }))

    const handleClose =()=>{
        dispatch(setOrderSuccess(false))
    }

    function handleRedirect() {
        dispatch(setOrderSuccess(false))
        history('/order')
    }
  return (
    <CustomDialog 
            isShow={isOrderSuccess} 
            handleClose={handleClose}
            icon={<HiCheck className="h-6 w-6 text-green-600" aria-hidden="true" />}
            title={t('dialog.order.orderSuccessTitle')}
            message={t('dialog.order.orderSuccessMessage')}
            btnText={t('dialog.order.orderSuccessBtn')}
            btnAction={handleClose}
            btnClose={true}
            btnCloseText={t('dialog.order.orderSuccessBtnClose')}
            btnCloseAction={handleRedirect}
            iconColor={'bg-green-100'}
        />
  )
}

export default OrderSuccess