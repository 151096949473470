import React, { useState, useEffect } from 'react';
import useScrollPosition from '../../hooks/useScrollPosition'; // Import the custom hook
import { BsTranslate } from 'react-icons/bs'
import { addWindowClass, removeWindowClass } from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux';
import { showLanguage } from '../../store/reducers/language';
import { useTranslation } from 'react-i18next';


const TopNavbar = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch()
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [inDefaultPosition, setInDefaultPosition] = useState(true);
  const scrollPosition = useScrollPosition();
  const { restoInfo } = useSelector(state => ({
    restoInfo: state.restoInformation.restoInfo
  }))


  const handleScroll = (scroll) => {
    const currentScrollPos = scroll;
    const isScrolledDown = prevScrollPos < currentScrollPos;

    setPrevScrollPos(currentScrollPos);
    setInDefaultPosition((prevScrollPos > currentScrollPos || currentScrollPos < 10) && currentScrollPos < 64)

    if ((prevScrollPos > currentScrollPos || currentScrollPos < 10) && currentScrollPos < 64) {
      addWindowClass('page-top')
    } else {
      removeWindowClass('page-top')
    }

    if (isScrolledDown) {
      addWindowClass('start-scrolling')
    } else {
      removeWindowClass('start-scrolling')
    }

    if (currentScrollPos > 64) {
      setVisible(false);
      addWindowClass('page-scrolled')
    } else {
      setVisible(true);
      removeWindowClass('page-scrolled')
    }
  };

  const handleLanguageToggle = () => {
    document.body.classList.add('disable-scroll');
    dispatch(showLanguage(true))
  }

  useEffect(() => {
    handleScroll(scrollPosition)
  }, [scrollPosition]);

  return (
    <div className={`w-full z-20 ${visible ? 'top-[0px]' : '-top-[64px]'} transition-all pt-safe text-[16px] text-md-light-on-surface dark:text-md-dark-on-surface sticky`}>
      <div className={`absolute w-full h-full left-0 top-0 ${inDefaultPosition ? 'bg-transparent' : 'bg-white'}`} >
      </div>
      <div className="flex relative items-center w-full overflow-hidden justify-start h-16 pl-safe pr-safe">
        <div className="whitespace-nowrap leading-tight text-start text-[22px] font-normal first:mx-1 flex " style={{}}>
          <div className='me-1'>
            <img src={`${restoInfo.resto_logo}`} className='rounded-full w-14 h-14' alt="" />
          </div>
          <div>
            <span className="text-slate-900 font-bold secondary-font text-sm">{restoInfo.resto_name}</span>
            <div className="font-normal leading-none opacity-85 third-font text-xs">
              {t('topNavBar.table')}: <span className='text-primary-600 '>{restoInfo.resto_table}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center h-full ms-auto me-1">
          <button onClick={handleLanguageToggle} className="py-1.5 px-1.5 me-4 light-shadow transition-colors bg-gray-50 border active:bg-gray-200 font-medium  text-gray-900 rounded-lg hover:bg-gray-100 disabled:opacity-50">
            <BsTranslate />
          </button>
        </div>
      </div>
    </div>
  )
}

export default TopNavbar