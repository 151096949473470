import React, { useState } from 'react'
import { setSearchBar } from '../../store/reducers/searchBar'
import { useDispatch, useSelector } from 'react-redux'
import { setCategoryFilter, setSearchFilter } from '../../store/reducers/filter'
import { useTranslation } from 'react-i18next';


const SearchBar = ({ type }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const [search, setSearch] = useState('')
    const { isSearchBarOpen, searchFilter, categoryFilter } = useSelector(state => ({
        isSearchBarOpen: state.searchBar.isSearchBarOpen,
        searchFilter: state.filter.searchFilter,
        categoryFilter: state.filter.categoryFilter
    }))

    const handleClickSearchBar = () => {
        dispatch(setSearchBar({ show: true, type: type }))
        document.body.classList.add('disable-scroll');
    }

    const handleChange = (e) => {
        // setSearch(e.target.value.trim())
        if (e.target.value.trim() !== '') {
            dispatch(setSearchFilter({ text: e.target.value.trim(), type: type }))
            if (categoryFilter) {
                dispatch(setCategoryFilter(null))
            }
        } else {
            dispatch(setSearchFilter(null))
        }
    }

    const handleTabKey = (e) => {
        if (e.key === 'Tab' && document.activeElement === e.target) {
          // Prevent the default tab key behavior
          e.preventDefault();
          // You can add custom logic here, such as navigating to the next/previous element
        }
      };

    return (
        <>
            {!isSearchBarOpen &&
                <div className=' py-3 bg-white mx-auto px-1 max-w-2xl    '>
                    <div className="relative shadow-2 rounded-xl">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg className={`w-4 h-4 ${type === 'pureVeg' ? 'text-primary-500' : type === 'nonVeg' ? 'text-red-500' : type === 'bar' ? 'text-black' : 'text-blue-500'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"></path>
                            </svg>
                        </div>
                        <input type="search" id="search-navbar"
                            // onClick={handleClickSearchBar}
                            onKeyDown={handleTabKey}
                            onChange={handleChange}
                            value={searchFilter ? searchFilter.text || '' : ''}
                            className="block form-input border-0 focus:ring-0 outline-none w-full p-2 py-3 pl-10 text-sm text-gray-900 focus:ring-0  rounded-xl bg-white dark:bg-gray-700 placeholder-gray-500  dark:placeholder-gray-400 dark:text-white "
                            placeholder={t('search.searchBarLabel')}/>
                    </div>
                </div>
            }
        </>
    )
}

export default SearchBar