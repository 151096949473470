import React, { useEffect, useRef, useState } from 'react';

import FoodCategory from './category/FoodCategory';
import DividerWithName from '../../modules/custom-components/DividerWithName';
import ProductCard from './product/ProductCard';
import { setCategoryShow } from '../../store/reducers/categoryShow';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';


const ProductTab = ({ type }) => {
    const dispatch = useDispatch();
    const { product, categoryFilter, searchFilter,mode } = useSelector((state) => ({
        product: state.menu.menuItemWithId,
        categoryFilter: state.filter.categoryFilter,
        searchFilter: state.filter.searchFilter,
        mode: state.viewMode.mode,
    }));
    const [recommended, setRecommended] = useState([]);
    const [data, setData] = useState({});
    const [productHeading, setProductHeading] = useState(null);
    const categoryRef = useRef(null);

    useEffect(() => {
        const filteredProductRec = Object.values(product[type] || {})?.filter(pro => pro?.pro_recomend === '1')
        setRecommended(filteredProductRec)
    }, [product, type])

    useEffect(() => {
        if (categoryFilter && categoryFilter.type === type) {
            let filter = {};
            Object.keys(product[type]).forEach((key) => {
                if (product[type][key].category_id === categoryFilter.id) {
                    filter[key] = product[type][key];
                }
            });
            setProductHeading(categoryFilter.name)
            setData(filter);
        } else if (searchFilter && searchFilter.type === type) {
            let filter = {};
            Object.keys(product[type]).forEach((key) => {
                if (product[type][key].eng_pro?.toLowerCase()?.includes(searchFilter.text?.toLowerCase()) || product[type][key].category_name?.toLowerCase()?.includes(searchFilter.text?.toLowerCase())) {
                    filter[key] = product[type][key];
                }
            });
            setProductHeading(searchFilter.text)
            setData(filter);
        } else {
            setData(product[type]);
            setProductHeading(null)
        }
    }, [categoryFilter, searchFilter, product, type]);

    useEffect(() => {
        const handleScroll = () => {
            const categoryElement = categoryRef.current;

            if (categoryElement) {
                const rect = categoryElement.getBoundingClientRect();
                const isAboveViewport = rect.top < 0;

                if (isAboveViewport) {
                    dispatch(setCategoryShow({ show: true, type: type }));
                } else {
                    dispatch(setCategoryShow({ show: false, type: '' }));
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch, type]);

    return (
        <>
            <div ref={categoryRef}>
                <FoodCategory type={type} />
            </div>


            {(recommended?.length > 0 && !categoryFilter && !searchFilter) &&
                <>
                    <DividerWithName premium={true}>{t('product.productRecommend')}</DividerWithName>

                    <div className='grid grid-cols-1 gap-y-4 px-2 pt-4 pb-4'>
                        {recommended.length > 0 ? (
                            <>
                                {recommended.map((data, i) => (
                                    <ProductCard mode={mode} productData={data} key={`productRecommend-${i}`} />
                                ))}
                            </>
                        ) : (
                            <div className='flex justify-center'>
                                Data Not Available
                            </div>
                        )}
                    </div>
                </>
            }

            <DividerWithName>
                {!productHeading?
                <>
                    {t('product.productTitle')}
                </>
                :
                <>"{productHeading}"</>    
            }
                
            </DividerWithName>

            <div className='grid grid-cols-1 gap-y-4 px-2 pt-4 pb-16'>
                {Object.keys(data || {}).length > 0 ? (
                    <>
                        {Object.values(data || {}).map((data, i) => (
                            <ProductCard mode={mode} productData={data} key={`productSample1-${i}`} />
                        ))}
                    </>
                ) : (
                    <div className='flex justify-center'>
                        Data Not Available
                    </div>
                )}
            </div>
        </>
    );
};

export default ProductTab;
