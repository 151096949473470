import React from 'react'

const DividerWithName = ({children,style,premium=false}) => {
    return (
        <div className={`flex items-center mt-6 mb-4 ${premium&& 'bg-gradient-to-b from-[#FFD700] to-[#fff] rounded-t-lg h-[40px] '}`} style={{...style}}>
            <hr className="flex-grow border-t border-gray-100" />
            <span className={`px-3 select-none ${premium? 'text-[#8d6401] font-bolder secondary-font text-base tracking-wide':'text-slate-600 third-font text-sm'}   font-medium`}>
                {children}
            </span>
            <hr className="flex-grow border-t border-gray-100" />
        </div>
    )
}

export default DividerWithName