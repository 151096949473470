import React, { useState, useRef, useEffect } from 'react'
import { LuAlignRight } from 'react-icons/lu'
import { useSelector, useDispatch } from 'react-redux'
import { setCategoryFilter, setSearchFilter } from '../../../store/reducers/filter'
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_API_URL

const CategoryChip = ({ handleClickCategory, cat, lang, categoryFilter }) => {
    const cardRef = useRef(null);

    useEffect(() => {
        if (categoryFilter?.id === cat.category_id) {
            cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [])

    useEffect(() => {
        if (categoryFilter?.id === cat.category_id) {
            cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [categoryFilter?.id, cat.category_id])

    return (
        <div ref={cardRef} onClick={() => handleClickCategory(cat?.category_id, cat?.[`${lang}_cat`])} className={`flex-none flex items-center py-2 ps-1 `}>
            <div className={`w-full flex bg-white shadow-4 gap-x-1 px-2 rounded-lg ${categoryFilter?.id === cat?.category_id ? 'bg-gray-300/80' : ''}`}>
                <div className="catImage flex justify-center items-center">
                    <img className='w-[25px] h-[25px] rounded-full object-contain' src={`${API_URL}/uploads/cat_img/${cat?.img1}`} alt="" />
                </div>
                <div className=" select-none catName text-sm flex items-center text-center text-slate-900 secondary-font capitalize">
                    {cat?.[`${lang}_cat`]}
                </div>
            </div>
        </div>
    )
}

const FoodCategoryChip = ({ categoryType }) => {
    const dispatch = useDispatch()
    const { i18n, t } = useTranslation();
    const lang = i18n.language;
    const { isCategoryShow } = useSelector(state => ({
        isCategoryShow: state.categoryShow.isCategoryShow,
    }))
    const [selected, setSelected] = useState(null)
    const { categoryItemWithId, searchFilter, categoryFilter } = useSelector(state => ({
        categoryItemWithId: state.category.categoryItemWithId,
        searchFilter: state.filter.searchFilter,
        categoryFilter: state.filter.categoryFilter
    }))

    const handleClickCategory = (id, name = "Category") => {
        setSelected(id)
        if (categoryFilter?.id !== id) {
            dispatch(setCategoryFilter({ type: categoryType, id: id, name: name }))
            if (searchFilter) {
                dispatch(setSearchFilter(null))
            }
        } else {
            setSelected(null)
            dispatch(setCategoryFilter(null))
        }
    }

    const data = categoryItemWithId[categoryType]

    return (
        <>
            {/* {isCategoryShow &&
            } */}
            <div className="categoryChipContainer sticky top-0 -translate-y-[7px] z-20" style={{ display: isCategoryShow ? 'block' : 'none' }}>
                <div className="mainContent flex overflow-hidden bg-white  overflow-x-auto gap-x-1 hide-scroll">
                    {/* <button className="flex-none py-3 w-[40px] flex justify-center items-center text-xl  text-slate-900 relative after:content-[''] after:absolute after:w-[1px] after:h-[60%] after:right-0 after:top-[20%]  after:bg-slate-400">
                        <LuAlignRight size={24} />
                    </button> */}
                    <div onClick={() => handleClickCategory(categoryFilter?.id)} key={`FullcateAllsmall-1`} className={`flex-none flex items-center py-2 ps-1 `}>
                        <div className={`w-full flex bg-white shadow-4 gap-x-1 px-2 rounded-lg `}>
                            <div className="catImage flex justify-center items-center">
                                <img className='w-[25px] h-[25px] rounded-full object-contain' src={require('../../../assets/images/logo/fudly_logo.png')} alt="" />
                            </div>
                            <div className="catName text-sm flex items-center text-center text-slate-900 secondary-font">
                                {t('category.allCategory')}
                            </div>
                        </div>
                    </div>
                    {Object.values(data || {})?.map((cat, index) => (
                        <CategoryChip key={`Fullcate-${index}`} handleClickCategory={handleClickCategory} cat={cat} lang={lang} categoryFilter={categoryFilter} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default FoodCategoryChip