import React, { useEffect, useState } from 'react'
import { BsCaretRightFill } from 'react-icons/bs'
import { TbMinus, TbPlus } from 'react-icons/tb';
import { increaseCartItemQuantity, decreaseCartItemQuantity, clearCart } from '../../store/reducers/menu'
import { showCheckOut } from '../../store/reducers/checkOut'
import { useDispatch, useSelector } from 'react-redux'
import { PiCaretLeftBold } from 'react-icons/pi'
import { Transition } from '@headlessui/react';
import addNewData from '../../api/addNewData';
import useFetchOrderData from '../../hooks/useFetchOrderData';
import { removeWindowClass } from '../../utils/helpers';
import { setOrderSuccess } from '../../store/reducers/orderDialog';
import { useTranslation } from 'react-i18next';
import smallLogo from '../../assets/images/logo/fudly_logo_without_circle.png'
import { BiSolidMessageSquareEdit } from 'react-icons/bi';
const MEDIA_URL = process.env.REACT_APP_MEDIA_URL

const ProductPreviewModal = () => {
    const { isShow } = useSelector(state => ({
        isShow: state.checkOut.isCheckOutShow
    }))

    return (
        <>
            <PreView isOpen={isShow} />
        </>
    )
}

const PreView = ({ isOpen }) => {
    const { t } = useTranslation();
    const { cart, productId } = useSelector(state => ({
        cart: state.menu.cartItem,
        productId: state.menu.menuItemWithId,
    }))
    const [data, setData] = useState([])
    const [totalQty, setTotalQty] = useState()
    const [comment, setComment] = useState('')
    const [preference, setPreference] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const fetchOrderData = useFetchOrderData('cart');
    const dispatch = useDispatch()

    useEffect(() => {
        const cartProduct = []
        let totalCount = 0
        let totalAmount = 0;
        cart?.forEach((pId) => {
            Object.values(productId).forEach((pro) => {
                if (pro[pId]) {
                    cartProduct.push(pro[pId])
                    totalCount += pro[pId].item_qty
                    totalAmount += (+pro[pId].product_price * pro[pId].item_qty)
                }
            })
        })
        setData(cartProduct)
        setTotalQty(totalCount)
        setTotalAmount(totalAmount)

    }, [cart, productId])

    useEffect(() => {
        setPreference(!!JSON.parse(localStorage.getItem('preference')))
    }, [])

    const handleClose = () => {
        dispatch(showCheckOut(false))
        // Remove the disable-scroll class from the root div (body)
        document.body.classList.remove('disable-scroll');
        removeWindowClass('start-scrolling')
    }

    const handleChange = (e)=>{
        if (e.target.name === 'preference'){
            setPreference(pre=>{
                localStorage.setItem('preference', !pre);
                return !pre;
            })
        }else{
            setComment(e.target.value)
        }
    }

    const handlePlaceOrder = async () => {
        const table_id = localStorage.getItem('table_id')
        setIsLoading(true)
        const orderData = data.map((ord) => {
            return { product_qty: ord.item_qty,order_prefrance:preference?'1':'0', product_id: ord.product_id, product_price: ord.product_price };
        });
        const formData = new FormData();
        formData.append('order_list', JSON.stringify(orderData))
        formData.append('table_id', table_id)
        if (comment.length > 0){
            formData.append('ord_comment', comment)
        }
        // formData.append('order_prefrance', preference?'1':'0')
        try {
            const orderIdOld = localStorage.getItem('order_id')
            if (!orderIdOld) {
                const response = await addNewData('order/', formData);
                if (response.status) {
                    localStorage.setItem('order_id', response.data.order_id);
                    localStorage.setItem('lastUpdated', 'not')
                    fetchOrderData(response.data.order_id, true)
                    setIsLoading(false)
                    dispatch(clearCart());
                    handleClose()
                    dispatch(setOrderSuccess(true))
                } else {
                    console.log("Some thing went wrong during place Order")
                }
            } else {
                const response = await addNewData(`api/order/ordid/${orderIdOld}`, formData);
                if (response.status) {
                    localStorage.setItem('lastUpdated', 'not')
                    fetchOrderData(localStorage.getItem('order_id'), true)
                    setIsLoading(false)
                    dispatch(clearCart());
                    handleClose()
                    dispatch(setOrderSuccess(true))
                } else {
                    console.log("Some thing went wrong during place Order")
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.log("🚀 ~ file: CartCheckOut.jsx:71 ~ handlePlaceOrder ~ error:", error)
        }
    }
    return (
        <Transition
            key={'cartCheckOut'}
            as={React.Fragment}
            show={isOpen}
            enter="transition-transform duration-300 ease-in-out"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition-transform duration-300 ease-in-out"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
        >
            <div style={{ maxHeight: '100%', minHeight: '100%' }} className=" top-full transition-transform duration-400 z-40  fixed rounded-t-2xl bg-[#F5F6FB] -translate-y-full w-full max-w-xl mx-auto">



                <div className="sheetHeader w-full z-40 relative bg-white" >
                    {/* <div className="absolute w-full h-full left-0 top-0 rounded-t-2xl" /> */}
                    <div className="flex relative justify-between items-center w-full overflow-hidden px-1 h-14 border-b border-gray-200">
                        <div className=" left flex items-center">
                            <button disabled={isLoading} onClick={handleClose} className={`p-2 text-slate-800 disabled:opacity-60 ${isLoading && 'cursor-not-allowed'}`}>
                                <PiCaretLeftBold size={24} />
                            </button>
                            <span className='text-slate-900 secondary-font text-xl pb-1'>
                                {t('cart.checkOutTitle')}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="sheetBody text-sm  relative overflow-y-auto pb-8" style={{ maxHeight: 'calc(100dvh - 160px)', minHeight: 'calc(100dvh - 160px)' }}>
                    <div className="w-full h-full p-3">
                        <h3 className='mb-4 mt-1 text-slate-700 text-center third-font '>{t('cart.checkOutHead', { totalQty: totalQty })} | &#8377;{totalAmount}</h3>
                        <div className='bg-white rounded-xl flex flex-col'>
                            {data.map((d, i) => (
                                <CartItems key={`cartFullViewItem${i}`} data={d} />
                            ))}
                        </div>
                    </div>

                    <div className="w-full h-full p-3">
                        <h3 className='mb-4 mt-1 text-slate-700 text-center third-font '>{t('cart.cartInstruction')}</h3>
                        <div className='bg-white rounded-xl flex flex-col p-3'>
                            <div className="bg-stone-50 flex pt-2 rounded-lg border-[1.896px] border-solid border-gray-400 border-opacity-50 items-start">
                                <BiSolidMessageSquareEdit size={'28'} className={'pl-2 shrink-0 text-gray-500'}/>
                                <textarea onChange={handleChange} name='comment' value={comment} rows={3} className='border-0 form-textarea focus:ring-0 rounded-b-lg resize-none w-full p-2 pt-0 text-slate-800 text-base bg-stone-50 outline-none style-scroll' placeholder={t('cart.cartInstructionPlace')} />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="sheetFooter py-3 min-h-[50px] w-full absolute bottom-0 px-2 flex flex-col justify-between items-start z-40 bg-white">
                    <p className="mb-2 ml-1 flex items-center">
                        <input type="checkbox" checked={preference} onChange={handleChange} name="preference" id="pref" className='form-checkbox focus:ring-0 w-4 h-4 text-primary-400 bg-gray-100 border-gray-300 rounded focus:outline-none' style={{borderRadius:'15px !mportant'}}/>
                        <label htmlFor="pref" className='ms-2 text-xs font-medium text-gray-900 dark:text-gray-300'>{t('cart.cartPreference')}</label>
                    </p>
                    <button
                        disabled={isLoading}
                        onClick={handlePlaceOrder}
                        className={`${isLoading && 'cursor-not-allowed'} disabled:opacity-75 flex text-xl secondary-font text-center justify-center items-center appearance-none py-1 transition-colors focus:outline-none cursor-pointer select-none overflow-hidden w-full relative duration-300 font-medium px-6 rounded-lg text-white bg-primary-600 h-14`}
                        tabIndex={0}
                    >
                        <span className='pb-1'>{t('cart.checkOutBtn')}</span> <BsCaretRightFill />
                    </button>
                </div>

                <Transition
                    key={'cartFullViewOverlay'}
                    // as={React.Fragment}
                    show={isLoading}
                    enter="ease-out duration-300 z-40"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div class="fixed z-30 w-full h-full left-0 top-0 inset-0 bg-gray-500 bg-opacity-75 transition-opacity max-w-xl mx-auto" onClick={handleClose}></div>
                    <div class="flex-col gap-4 w-full flex items-center justify-center absolute top-[50%] -translate-y-[80px] left-[0%] z-40">
                        <div class="w-28 h-28 border-0 text-primary-500 text-4xl  border-gray-300 flex items-center justify-center border-t-primary-500 rounded-full">
                            <img src={smallLogo} height="100%" width="100%" class="" alt="" srcset="" />
                        </div>
                        <span className='secondary-font text-xl text-white'>{t('cart.checkOutLoading')}
                            <span className='animate-ping'>.</span>
                            <span className='animate-ping'>.</span>
                            <span className='animate-ping'>.</span>
                        </span>
                    </div>
                </Transition>
            </div>
        </Transition>
    )
}

const CartItems = ({ data }) => {
    const dispatch = useDispatch()
    const { i18n } = useTranslation();
    const lang = i18n.language;

    const handleDecrement = () => {
        dispatch(decreaseCartItemQuantity(data.product_id))
    }

    const handleIncrement = () => {
        dispatch(increaseCartItemQuantity(data.product_id))
    }
    return (
        <div className="flex items-start w-full px-3 py-3">
            <img
                className="w-12 h-12 rounded-lg object-cover mr-2 shadow"
                src={`${MEDIA_URL}/${data?.img1}`}
                alt=""
            />
            <div className="flex w-full justify-between secondary-font">
                <div className="mr-4">
                    <h2 className="text-base  font-semibold text-slate-900 -mt-1 leading-5 capitalize">
                        {data[`${lang}_pro`]}
                    </h2>
                    <p className="text-slate-600 font-semibold text-sm mt-1">&#8377;{data.product_price}</p>
                </div>
                <div className='flex flex-col '>
                    <div className="w-[5rem] py-1 px-2 text-sm text-primary-500 bg-[#f8fcf9] border border-primary-500 font-semibold secondary-font  rounded-lg flex justify-between items-center gap-1">
                        <button className="decrease" onClick={handleDecrement} >
                            <TbMinus />
                        </button>
                        <span className="qtyCount font-semibold text-primary-600 ">
                            {data.item_qty}
                        </span>
                        <button className="increase" onClick={handleIncrement}>
                            <TbPlus />
                        </button>
                    </div>
                    <p className="text-slate-600 font-semibold text-sm text-right mt-1">&#8377;{parseInt(data.product_price) * data.item_qty}</p>
                </div>
            </div>
        </div>
    )
}

export default ProductPreviewModal