import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setSearchBar } from '../../store/reducers/searchBar'
import { PiCaretLeftBold } from 'react-icons/pi'
import { Transition } from '@headlessui/react'

const SearchBarFullView = () => {
    const { isSearchBarOpen, type } = useSelector(state => ({
        isSearchBarOpen: state.searchBar.isSearchBarOpen,
        type: state.searchBar.searchType
    }))
    return (
        <>
            {isSearchBarOpen &&
                <SearchModal type={type} />
            }
        </>
    )
}

const SearchModal = ({ type }) => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const handleClickSearchBar = () => {
        dispatch(setSearchBar({ show: false, type: '' }))
        document.body.classList.remove('disable-scroll');
    }
    const handleOnChange = (event) => {
        setSearch(event.target.value)
    }
    return (
        <>
            <div className=" z-[40] w-full h-screen left-0 top-0 bg-white duration-400 fixed  "></div>
            <div className='  z-[40] w-full bg-white mx-auto  max-w-2xl fixed top-0 '>
                <div className="py-3 px-1 border-b border-b-gray-200">
                    <div class="relative shadow-2 rounded-xl">
                        <div onClick={handleClickSearchBar} class="absolute inset-y-0 left-0 flex items-center pl-3">
                            <span class={`w-5 h-5 flex items-center cursor-pointer ${type === 'pureVeg' ? 'text-primary-500' : type === 'nonVeg' ? 'text-red-500' : type === 'bar' ? 'text-black' : 'text-blue-500'}`} >
                                <PiCaretLeftBold size={24} />
                            </span>
                        </div>
                        <input onChange={handleOnChange} type="text" id="search-navbar" class=" outline-none focus:outline-none. block w-full p-2 py-3 pl-10 text-sm text-gray-900 focus:ring-0  rounded-xl bg-white dark:bg-gray-700 placeholder-gray-500  dark:placeholder-gray-400 dark:text-white " placeholder="Search..." />
                    </div>
                </div>
                <div className='px-2 pt-4'>
                    {search.length > 0 ?
                        <h4 className='text-lg text-slate-800 secondary-font'>
                            Search for <span className='text-sm text-slate-600 third-font ms-1'>"{search}"</span>
                        </h4>
                        :
                        <h4 clas sName='text-lg text-slate-800 secondary-font'> POPULAR Food </h4>
                    }
                </div>

                <div className='flex flex-col mt-3'>
                    <div className="flex items-start w-full px-3 py-3">
                        <img
                            className="w-12 h-12 rounded-lg object-cover mr-2 shadow"
                            src={require('../../assets/images/food/food-item/cake.png')}
                            alt=""
                        />
                        <div className="flex w-full justify-between secondary-font">
                            <div className="mr-4">
                                <h2 className="text-base  font-semibold text-slate-900  leading-5">
                                    chocolate cake
                                </h2>
                                <p className="text-slate-600 font-semibold text-sm ">cake</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start w-full px-3 py-3">
                        <img
                            className="w-12 h-12 rounded-lg object-cover mr-2 shadow"
                            src={require('../../assets/images/food/food-item/chicken.png')}
                            alt=""
                        />
                        <div className="flex w-full justify-between secondary-font">
                            <div className="mr-4">
                                <h2 className="text-base  font-semibold text-slate-900  leading-5">
                                    Adeshwari Fried Chicken
                                </h2>
                                <p className="text-slate-600 font-semibold text-sm ">Chicken</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start w-full px-3 py-3">
                        <img
                            className="w-12 h-12 rounded-lg object-cover mr-2 shadow"
                            src={require('../../assets/images/food/food-item/pizza.png')}
                            alt=""
                        />
                        <div className="flex w-full justify-between secondary-font">
                            <div className="mr-4">
                                <h2 className="text-base  font-semibold text-slate-900  leading-5">
                                    Oven Story Pizza - Standout Toppings
                                </h2>
                                <p className="text-slate-600 font-semibold text-sm ">Pizza</p>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    )
}

export default SearchBarFullView