import React from 'react'
import CustomDialog from '../../modules/dialog/CustomDialog'
import { t } from 'i18next';

const UnsupportedBrowser = ({ isOpen, handleClose }) => {

    return (
        <CustomDialog
            isShow={isOpen}
            handleClose={handleClose}
            icon={<span role="img" aria-label="Sad Emoji" className="text-2xl"> 😔 </span>}
            title={t('dialog.notification.unsupportedTitle')}
            message={t('dialog.notification.unsupportedMessage')}
            btnText={t('dialog.btn.close')}
            btnAction={handleClose}
            iconColor={'bg-red-100'}
        />
    )
}

export default UnsupportedBrowser