import React, { useEffect } from 'react';
import { HiX, HiOutlineBell } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { useNavigate } from "react-router-dom";
import { setOrderUpdate } from '../../store/reducers/orderDialog';
import messageSound from '../../assets/audio/juntos-607.mp3'

const OrderStatusUpdate = () => {
    const dispatch = useDispatch()
    const { isOrderUpdate } = useSelector((state) => ({
        isOrderUpdate: state.orderDialog.isOrderUpdate
    }))

    const handleClose = () => {
        dispatch(setOrderUpdate(false))
    }

    return (
        <>
            {isOrderUpdate ?
                <StatusDialog handleClose={handleClose} />
                :
                null
            }
        </>
    );
};

const StatusDialog = ({ handleClose }) => {
    const history = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            handleClose();
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    // function playPopupSound() {
    //     const audio = new Audio('../../assets/audio/juntos-607.mp3'); // Replace with the actual path to your audio file
    //     audio.play();
    // }

    useEffect(() => {
        if (document.hasFocus()){
            const sound = new Audio(messageSound)
            sound.play()
        }
        // playPopupSound()
    }, [])

    const handleNotiFicationClick=()=>{
        handleClose();
        history('/order')
    }
    return (
        <div onClick={handleNotiFicationClick} className="fixed top-[10px] right-2 flex items-center justify-between w-[300px] bg-white rounded-lg p-4 shadow-2 z-[100] transition duration-100 ease-out transform scale-95 opacity-100 hover:scale-102 hover:opacity-100">
            <div className="flex items-center">
                {/* <HiOutlineBell className="text-primary-500 mr-2 w-12 h-12" /> */}
                <div className="relative w-12 h-12 pr-2">
                    <span className="absolute right-2 inset-0 flex items-center justify-center animate-ping">
                        <span className="w-5 h-5 bg-primary-300 rounded-full" />
                    </span>
                    <HiOutlineBell className="text-primary-500 mr-2 w-full h-full" />
                </div>
                {/* <p className="text-sm text-gray-800" dangerouslySetInnerHTML={{ __html: randomMessage }}></p> */}
                <p className="text-sm text-gray-800" >
                    {t('dialog.order.orderUpdates')}
                </p>
            </div>
            <button
                type="button"
                className="text-gray-400 hover:text-gray-500 transition-all duration-300 focus:outline-none"
                onClick={handleClose}
            >
                <HiX className="h-5 w-5" aria-hidden="true" />
            </button>
        </div>
    )
}

export default OrderStatusUpdate;
