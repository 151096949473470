import React, { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import TopNavbar from '../nav-bar/TopNavbar';
import BottomTab from '../bottom-tab/BottomTab';
import ProductPreviewModal from '../../components/home/product/ProductPreviewModal'
import SearchBarFullView from '../search/SearchBarFullView'
import LanguageOptions from '../../components/language/LanguageOptions';
import BottomTabGuest from '../bottom-tab/BottomTabGuest';

const Guest = () => {
  // const authentication = useSelector(state => state.auth.authentication);
  const authentication = true;
  const [isAppLoaded, setIsAppLoaded] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsAppLoaded(Boolean(authentication));
    }, 3000)
  }, [authentication]);

  
  const getAppTemplate = useCallback(() => {
    console.log('it component renders')
    if (!isAppLoaded) {
      return (
        <>Loading </>
      );
    }
    return (
      <>
        <div className="content-wrapper layout-wrapper layout-content-navbar min-w-screen min-h-screen max-w-xl mx-auto ">
          {/* <div className="pt-3 layout-container" /> */}
          <SearchBarFullView />
          <TopNavbar />
          <section className="content ">
            <Outlet />
          </section>
          <ProductPreviewModal />
          <BottomTabGuest /> 
          <LanguageOptions />
          {/* <Page>
          </Page> */}
        </div>
      </>
    );
  }, [isAppLoaded]);

  return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Guest;
