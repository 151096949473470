import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mode: 'user',
};

export const viewModeSlice = createSlice({
    name: 'viewMode',
    initialState,
    reducers: {
        setViewMode: (state, action) => {
            state.mode = action.payload
        }
    }
});

export const { setViewMode } = viewModeSlice.actions;

export default viewModeSlice.reducer;
