import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    chooseLanguage: false,
};

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        showLanguage: (state, action) => {
            state.chooseLanguage= action.payload;
        }
    }
});

export const { showLanguage } = languageSlice.actions;

export default languageSlice.reducer;
