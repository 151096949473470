import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationEN from '../locales/en/translation.json';
import translationHI from '../locales/hi/translation.json';

// the translations
const resources = {
  eng: {
    translation: translationEN
  },
  hin: {
    translation: translationHI
  },
};
const defaultLang = JSON.parse(localStorage.getItem('language'))? JSON.parse(localStorage.getItem('language')): 'eng'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLang,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true
    }
  });

export default i18n;
