import React, { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import TopNavbar from '../nav-bar/TopNavbar';
import CartFloatView from '../../components/cart/CartFloatView';
import CartCheckOut from '../../components/cart/CartCheckOut';
import BottomTab from '../bottom-tab/BottomTab';
import ProductPreviewModal from '../../components/home/product/ProductPreviewModal'
import SearchBarFullView from '../search/SearchBarFullView'
import LogoutByAdmin from '../../dialogs/log-out/LogOutByAdmin';
import LanguageOptions from '../../components/language/LanguageOptions';
import useFetchOrderData from '../../hooks/useFetchOrderData';
import { fetchData } from '../../api';
import OrderSuccess from '../../dialogs/order/OrderSuccess';
import OrderStatusUpdate from '../../dialogs/order/OrderStatusUpdate';
import CanceledOrderItem from '../../dialogs/order/CanceledOrderItem';
import NotificationPermission from '../../dialogs/notification/NotificationPermission';
import NotificationRequest from '../../dialogs/notification/NotificationRequest';
import UnsupportedBrowser from '../../dialogs/notification/UnsupportedBrowser';
import { showInfoLogOut } from '../../store/reducers/logOut';
import { useDispatch } from 'react-redux';

const Main = () => {
  // const authentication = useSelector(state => state.auth.authentication);
  const dispatch = useDispatch()
  const authentication = true;
  const [isAppLoaded, setIsAppLoaded] = useState(true);
  const [askForNotification, setAskForNotification] = useState(false);
  const [unsupportedBrowser, setUnsupportedBrowser] = useState(false);
  const [requestNotification, setRequestNotification] = useState(false);
  const fetchOrderData = useFetchOrderData('main');

  useEffect(() => {
    setTimeout(() => {
      setIsAppLoaded(Boolean(authentication));
    }, 3000)
  }, [authentication]);

  function fetchFcmToken() {
    if (!('Notification' in window)) {
      // Browser does not support notifications
      setUnsupportedBrowser(true)
      return;
    }

    setAskForNotification(true)
  }

  const notificationDialog=()=>{
    setRequestNotification(true)
  }

  const checkingLoginStatus = async () => {
    const tableId = localStorage.getItem('table_id');
    const pageName = (window.location.pathname).split('/')[1]
    if (pageName !== 'orderDone' && pageName !== 'viewMenu/guest' && !!tableId) {
      try {
        const logStatus = await fetchData(`api/allfunctionuser/table_alloted_status/${tableId}`, 'logStatus')
        if (logStatus.status) {
          dispatch(showInfoLogOut(true))
        }
      } catch (error) {
        // console.log('error in fetch lgin information', error)
      }
    }
  }

  useEffect(() => {
    fetchOrderData(localStorage.getItem('order_id'), true)
    if (!JSON.parse(localStorage.getItem('notificationIdFetch'))) {
      // Call fetchFcmToken() after a delay of 5 seconds
      setTimeout(fetchFcmToken, 5000);
    }
    const fetchDataOrder = async () => {
      if (!!localStorage.getItem('order_id') && localStorage.getItem('order_id') !== 'null') {
        try {
          const data = await fetchData(`api/order/newproodrusr/${localStorage.getItem('order_id')}`, 'order Data');

          if (data.status) {
            const updatedDate = data.data.updated_date;
            // Get the last updated date from local storage
            const storedLastUpdated = localStorage.getItem('lastUpdated');
            // Store the new last updated date in local storage
            localStorage.setItem('lastUpdated', updatedDate);

            // Compare the stored last updated date with the new one
            if (storedLastUpdated !== updatedDate && storedLastUpdated !== 'not') {
              console.log("🚀 ~ file: Main.jsx:46 ~ fetchDataOrder ~ storedLastUpdated:", storedLastUpdated,updatedDate)
              // Reload the page or fetch new order data
              fetchOrderData(localStorage.getItem('order_id'), false)
            }

          }
        }catch (error) {
          console.error('Error fetching order data:', error);
        }
        checkingLoginStatus()
      }else{
        checkingLoginStatus()
      }
    };
    const intervalId = setInterval(() => {
      // Fetch order data at the specified interval
      fetchDataOrder()
    }, 5000);

    return () => {
      // Clear the interval when the component unmounts to avoid memory leaks
      clearInterval(intervalId);
    };
  }, [])

  const handleClose = (setFunction)=>{
    setFunction(false)
  }

  const getAppTemplate = useCallback(() => {
    console.log('it component renders')
    if (!isAppLoaded) {
      return (
        <>Loading </>
      );
    }
    return (
      <>
        <div className="content-wrapper layout-wrapper layout-content-navbar min-w-screen min-h-screen max-w-xl mx-auto ">
          {/* <div className="pt-3 layout-container" /> */}
          <SearchBarFullView />
          <TopNavbar />
          <section className="content ">
            <Outlet />
          </section>
          <OrderStatusUpdate />
          <CartFloatView />
          <ProductPreviewModal />
          <CartCheckOut />
          <BottomTab />
          <LanguageOptions />
          <OrderSuccess />
          <LogoutByAdmin />
          <CanceledOrderItem />
          <NotificationPermission notificationDialog={notificationDialog} isOpen={askForNotification} handleClose={() => handleClose(setAskForNotification)} />
          <NotificationRequest isOpen={requestNotification} handleClose={() => handleClose(setRequestNotification)} />
          <UnsupportedBrowser isOpen={unsupportedBrowser} handleClose={() => handleClose(setUnsupportedBrowser)} />
          {/* <Page>
          </Page> */}
        </div>
      </>
    );
  }, [isAppLoaded,askForNotification,requestNotification,unsupportedBrowser]);

  return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Main;
