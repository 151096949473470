import React, { useState, useEffect } from 'react';
import useScrollPosition from '../../hooks/useScrollPosition'; // Import the custom hook
import { useDispatch, useSelector } from 'react-redux';
import CartFullViewModal from './CartFullViewModal'
import { showCheckOut } from '../../store/reducers/checkOut'
import { useTranslation } from 'react-i18next';

const MEDIA_URL = process.env.REACT_APP_MEDIA_URL

const CartFloatView = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const [cartDetail, setCartDetail] = useState({})
    const { cart, productId, cartIsOpen } = useSelector(state => ({
        cart: state.menu.cartItem,
        productId: state.menu.menuItemWithId,
        cartIsOpen: state.menu.cartIsOpen
    }))
    const [sheetOpened, setSheetOpened] = useState(false);

    useEffect(() => {
        const imageList = []
        let totalCount = 0
        let totalAmount = 0

        cart?.forEach((id, index) => {
             Object.keys(productId).forEach(pro=>{
                if(productId[pro][id]){
                    const currentItem = productId[pro][id]
                    if (index <= 2) {
                        imageList.push(`${MEDIA_URL}/${currentItem?.img1}`)
                    }
        
                    totalCount += currentItem.item_qty
                    totalAmount += parseFloat(currentItem.product_price) * currentItem.item_qty
                }
             })  

        })
        setCartDetail({ imageList: imageList, totalCount: totalCount, totalAmount: totalAmount })
    }, [cart, productId])


    const handleOpen = () => {
        setSheetOpened(true)
        // Add the disable-scroll class to the root div (body)
        document.body.classList.add('disable-scroll');
    }
    const handleClose = () => {
        setSheetOpened(false)
        // Remove the disable-scroll class from the root div (body)
        document.body.classList.remove('disable-scroll');
    }

    const handleShowCheckOut = ()=>{
        dispatch(showCheckOut(true))
        document.body.classList.add('disable-scroll');
    }

    return (
        <>
            {cartIsOpen ?
                <>
                    <div className={`w-full floatedCart z-20  transition-all text-[16px] text-md-light-on-surface dark:text-md-dark-on-surface fixed max-w-xl`}>
                        <div className="flex relative items-center w-full justify-start h-16 px-2">
                            {/* nav - start */}
                            <div className=" mx-auto flex w-full justify-between gap-8 bg-white shadow-md p-2 rounded-xl sm:max-w-md   sm:text-sm">
                                <div className='flex gap-x-1'>
                                    <div class="flex -space-x-8 relative">
                                        {cartDetail?.imageList?.map((url, i) => (
                                            <img key={`cartImages${i}`} src={url} class="w-10 h-10 border-2 rounded-full " alt="" />
                                        ))}
                                    </div>
                                    <div className="">
                                        <h5 onClick={handleOpen} className=" text-slate-800 secondary-font underline decoration-slate-500">{t('cart.cartFloatTitle')}</h5>
                                        <p className='text-sm text-slate-600'>{cartDetail?.totalCount || 0} {t('cart.cartFloatHead')}</p>
                                    </div>
                                </div>
                                <button onClick={handleShowCheckOut} className="px-4 bg-primary-600 flex justify-center items-center flex-col rounded-xl ">
                                    <span className='text-white text-sm secondary-font'>&#8377;{cartDetail?.totalAmount || 0}</span>
                                    <span className='text-primary-100 text-xs third-font'>{t('cart.cartFloatBtn')}</span>
                                </button>
                            </div>
                            {/* nav - end */}
                        </div>
                    </div>
                    {/* {sheetOpened &&
                    } */}
                    <CartFullViewModal isOpen={sheetOpened} handleClose={handleClose} />
                </>
                :
                ''
            }
        </>

    )
}

export default CartFloatView